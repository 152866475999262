import * as _ from "lodash";
import { BehaviorSubject, Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Page, PageSection } from './models/page.model'
import { NotificationService } from "../services/notification/notification.service";

@Injectable()
export class PageService {

  private base_api_url: string = 'api/page';
  pages: Observable<Page[]>
  private pages$: BehaviorSubject<Page[]>;
  private dataStore: {
    pages: Page[]
  };
  isSaved: Observable<boolean>;
  private isSaved$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private notificationService:NotificationService) {
    this.dataStore = { pages: [] };
    this.pages$ = <BehaviorSubject<Page[]>>new BehaviorSubject([]);
    this.pages = this.pages$.asObservable();

    this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.isSaved = this.isSaved$.asObservable();
  }

  loadAll() {
    let $this = this;
    return this.http.get<Page[]>(`${this.base_api_url}?excludeRelated`, { observe: 'response' }).subscribe(resp => {
      let data:Page[] = new Array<Page>();
      resp.body.forEach(i => data.push(Page.createFrom(i)));
      $this.dataStore.pages = data;
      $this.pages$.next(({ ... $this.dataStore}).pages);
    }, error => {
      error.displayMessage = 'Could not load pages.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading pages, please try again later!');
      throw error;
    });
  }

  loadAllData() {
    return this.http.get<Page[]>(this.base_api_url);
  }

  load(id: string) {
    return this.http.get<Page>(`${this.base_api_url}/${id}`).subscribe(data => {
      let notFound = true;

      this.dataStore.pages.forEach((item, index) => {
        if (item._id === data._id) {
          this.dataStore.pages[index] = data;
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.pages.push(data);
      }

      this.pages$.next(({ ... this.dataStore}).pages);
    }, error => {
      error.displayMessage = 'Could not load page.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading page, please try again later!');
      throw error;
    });
  }

  loadData(id: string) {
    return this.http.get<Page>(`${this.base_api_url}/${id}?includeBase64`)
            .map(page => {
              return Page.createFrom(page);
            });
  }

  loadDataByName(name: string) {
    return this.http.get<Page>(`${this.base_api_url}/name/${name}?includeBase64`);
  }


  create(page: Page) {
    this.processIsSaved();

    return this.http.post<Page>(this.base_api_url, page).subscribe(data => {
        console.log('successful create');
        this.dataStore.pages.push(data);
        this.pages$.next(({ ... this.dataStore}).pages);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Page has been created');
      }, error => {
        error.displayMessage = 'Could not create page.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error creating page, please try again later!');
        throw error;
      });
  }

  update(page: Page) {
    this.processIsSaved();

    return this.http.put<Page>(`${this.base_api_url}/${page._id}`, page)
      .subscribe(data => {
        this.dataStore.pages.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.pages[i] = data; }
        });

        this.pages$.next(({ ... this.dataStore}).pages);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Page has been updated');
      }, error => {
        error.displayMessage = 'Could not update page.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error updating page, please try again later!');
        throw error;
      });
  }

  updateSectionOrder(sections: PageSection[]) {
    this.processIsSaved();

    let _toBeSaved = _.map(sections, (section) => {
      let _section = new PageSection(null, section._id, null, null, section.order);
      return _section;
    });
    return this.http.put<PageSection[]>(`api/page/section/order`, _toBeSaved)
      .subscribe(data => { 
        this.notificationService.notifyInformation('Page section order has been updated');
      }, error => {
        error.displayMessage = 'Could not update order.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error updating section order of the page, please try again later!');
        throw error;
      });
  }

  remove(id: string) {
    return this.http.delete(`${this.base_api_url}/${id}`).subscribe(response => {
      this.dataStore.pages.forEach((t, i) => {
        if (t._id === id) { this.dataStore.pages.splice(i, 1); }
      });

      this.pages$.next(({ ... this.dataStore}).pages);
      this.notificationService.notifyInformation('Page has been updated');
    }, error => {
      error.displayMessage = 'Could not delete page.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error deleting page, please try again later!');
      throw error;
    });
  }

  private processIsSaved() {
    this.isSaved
      .subscribe((value) => {
        console.log("service subscribe - " + value);
        if (value === true)
        { this.isSaved$.next(false); }
      });
  }
}
