import { Component, OnInit, NgZone, Injector, ApplicationRef } from '@angular/core';
import { ToasterService, ToasterConfig, Toast, BodyOutputType } from 'angular2-toaster';
import { NotificationService, NotificationType } from '../../../@core/services/notification/notification.service';

import 'style-loader!angular2-toaster/toaster.css';

enum ToastType {
  Default = 'default', 
  Info = 'info', 
  Success = 'success', 
  Warning = 'warning', 
  Error = 'error',
};

@Component({
  selector: 'citi-notification',
  providers: [ToasterService],
  // styleUrls: ['./notifications.component.scss'],
  styles: [``],
  template: `
      <toaster-container [toasterconfig]="config">
      </toaster-container>
      `
})
export class NotificationsComponent implements OnInit {
  constructor(
    private toasterService: ToasterService,
    private notificationService: NotificationService,
    private ngZone : NgZone,
    private injector: Injector,
  ) {}

  config: ToasterConfig;

  position = 'toast-top-right';
  animationType = 'flyLeft';
  timeout = 7000;
  toastsLimit = 10;
  type = 'default';

  isNewestOnTop = true;
  isHideOnClick = true;
  isDuplicatesPrevented = true;
  isCloseButton = true;

  // KEEP: for informational purposes
  // types: string[] = ['default', 'info', 'success', 'warning', 'error'];
  // animations: string[] = ['fade', 'flyLeft', 'flyRight', 'slideDown', 'slideUp'];
  // positions: string[] = ['toast-top-full-width', 'toast-bottom-full-width', 'toast-top-left', 'toast-top-center',
  //   'toast-top-right', 'toast-bottom-right', 'toast-bottom-center', 'toast-bottom-left', 'toast-center'];

  public makeDefaultToast(title: string, content: string) {
    this.showToast(ToastType.Default, title, content);
  }

  public makeInfoToast(title: string, content: string) {
    this.showToast(ToastType.Info, title, content);
  }

  public makeSuccessToast(title: string, content: string) {
    this.showToast(ToastType.Success, title, content);
  }

  public makeWarningToast(title: string, content: string) {
    this.showToast(ToastType.Warning, title, content);
  }

  public makeErrorToast(title: string, content: string) {
    this.showToast(ToastType.Error, title, content, 0);
  }

  private showToast(type: string, title: string, body: string, timeout = this.timeout) {
    this.config = new ToasterConfig({
      positionClass: this.position,
      timeout: timeout,
      newestOnTop: this.isNewestOnTop,
      tapToDismiss: this.isHideOnClick,
      preventDuplicates: this.isDuplicatesPrevented,
      animation: this.animationType,
      limit: this.toastsLimit,
    });
    const toast: Toast = {
      type: type,
      title: title,
      body: body,
      timeout: timeout,
      showCloseButton: this.isCloseButton,
      bodyOutputType: BodyOutputType.TrustedHtml,
    };
    this.toasterService.popAsync(toast);
  }

  clearToasts() {
    this.toasterService.clear();
  }

  ngOnInit(): void {
    this.notificationService
            .notification$
            .subscribe(message => { 
              if (message != null) {
                console.log(message.message);

                if (message.type == NotificationType.Warning){
                  this.makeWarningToast(message.title, message.message);
                } 
                else if (message.type == NotificationType.Error)
                {
                  this.makeErrorToast(message.title, message.message);
                }
                else {
                  this.makeDefaultToast(message.title, message.message);
                }
              }
            });
  }
}
