import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let userInfo = sessionStorage.userInfo;
        if (userInfo) { userInfo = JSON.parse(userInfo); }
        if (userInfo && userInfo.token) {
            request = request.clone({
                setHeaders: { 
                    // Authorization: `Bearer ${userInfo.token}`
                    'lwc-ident' : `${userInfo.token}`
                }
            });
        }

        return next.handle(request);
    }
}