import { Office } from "./office.model";
import { Language } from "./language.model";
import { Document } from "./document.model";

export class ArticleCategory {
  constructor(
    public __v: number = 0,
    public _id: string = null,
    public name: string = null,
    public language: Language = null,
    public isActive: boolean = null
    ) { }

    static createFrom(o: Object) {
      const model = new ArticleCategory();
      return Object.assign(model, o);
    }
}

export class Article {
  constructor(
    public __v: number = 0,
    public _id: string = null,
    public title: string = null,
    public category: ArticleCategory = null,
    public author: string = null,
    public thumbnailBase64: string = null,
    public headerBase64: string = null,
    public inArticleBase64: string = null,
    public content: string = null,
    public relatedArticles: Article[] = null,
    public createdDate: Date = null,
    public blurb: string = null,
    public published: boolean = false,
    public location: Office = null,
    public createdBy: string = null,
    public relatedDocuments: Document[] = null
    ) { }

    static createFrom(o: Object) {
      const model = new Article();
      return Object.assign(model, o);
    }
}
