import { Office } from "./office.model";
import { Article } from "./article.model";
import { Event } from "./event.model";

export class LandingSlide {
  constructor(
    public __v: number = 0,
    public _id: string = null,
    public type: string = null,
    public layout: string = null,
    public color: string = null,
    public title: string = null,
    public subtitle: string = null,
    //public buttonText: string = null,
    public videoType:string = null,
    public videoId:string = null,
    public imageBase64: string = null,
    public urlType: string = null,
    public url: string = null,
    public urlId: string = null,
    public location: Office = null,
    public article: Article = null,
    public event: Event = null,
    public sortOrder: number = null,
    public createdBy: string = null
  ) { }

  static createFrom(o: Object) {
    const model = new LandingSlide();
    return Object.assign(model, o);
}
}
