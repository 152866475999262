import { BehaviorSubject, Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Event, EventContent } from './models/event.model'
import { PaginatedResponse } from './models/paginatedResponse.model';
import { NotificationService } from '../services/notification/notification.service';
import { currentUserID, currentUserLocation, isSuperAdmin } from '../utils/session';
import { environment } from '../../../environments/environment';
import { File } from './models/file.model';
import { FileService } from './file.service';

@Injectable()
export class EventService {

  private base_api_url: string = 'api/event';
  events: Observable<Event[]>
  private events$: BehaviorSubject<Event[]>;
  private dataStore: {
    events: Event[]
  };
  isSaved: Observable<boolean>;
  private isSaved$: BehaviorSubject<boolean>;
  private userLoc: string;
  private isSA: boolean;
  private userId : string;

  constructor(private http: HttpClient, private notificationService:NotificationService, private fileService:FileService) {
    this.dataStore = { events: [] };
    this.events$ = <BehaviorSubject<Event[]>>new BehaviorSubject([]);
    this.events = this.events$.asObservable();

    this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.isSaved = this.isSaved$.asObservable();

    this.userLoc = currentUserLocation();
    this.isSA = isSuperAdmin();
    this.userId = currentUserID();
  }

  loadAll() {
    let $this = this;

    // 9/30/2022 filter by regional Admin and location
    let url = "";
    if (!this.isSA && environment.viewUserCreatedData) {
      url = "?fromAdmin=true" + "&authorId=" + this.userId;
    } else if (!this.isSA && !environment.viewUserCreatedData) {
      url = "/office/" + this.userLoc + "?fromAdmin=true";
    } else {
      url = "?fromAdmin=true";
    }

    return this.http.get<Event[]>(`${this.base_api_url}${url}`, { observe: 'response' }).subscribe(resp => {
      let data:Event[] = new Array<Event>();
      resp.body.forEach(i => {
        data.push(Event.createFrom(i));
      });
      $this.dataStore.events = data;
      $this.events$.next(({ ... $this.dataStore}).events);
    }, error => {
      error.displayMessage = 'Could not load events.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading Events, please try again later!');
      throw error;
    });
  }

  loadAllData() {
    return this.http.get<Event[]>(this.base_api_url);
  }

  load(id: string) {
    return this.http.get<Event>(`${this.base_api_url}/${id}`).subscribe(data => {
      let notFound = true;

      this.initializeSubcontents(data);

      this.dataStore.events.forEach((item, index) => {
        if (item._id === data._id) {
          this.dataStore.events[index] = data;
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.events.push(data);
      }

      this.events$.next(({ ... this.dataStore}).events);
    }, error => {
      error.displayMessage = 'Could not load event.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading Event, please try again later!');
      throw error;
    });
  }

  loadData(id: string) {
    return this.http.get<Event>(`${this.base_api_url}/${id}?includeBase64`)
      .map(event => {
        if (event.startDate) { event.startDate = new Date(String(event.startDate)); }
        if (event.endDate) { event.endDate = new Date(String(event.endDate)); }
        this.initializeSubcontents(event);
        return event;
      });
  }

  initializeSubcontents(event: Event) {
    if ((event.contents != null) && (event.contents.length < 2)) {
      for (let i = event.contents.length; i < 2; i++) {
        event.contents.push(new EventContent('', ''));
      }
    }
  }

  async create(event: Event) {
    this.processIsSaved();

    if (event.calendarFile && event.calendarFile.file && event.calendarFile.file.name) {
      event.calendarFile = await this.fileService.createForModel(new File(0, null, event.calendarFile.file.name, event.calendarFile.file));
    } else {
      event.calendarFile = null;
    }

    return this.http.post<Event>(this.base_api_url, event).subscribe(data => {
        console.log('successful create');
        this.dataStore.events.push(data);
        this.events$.next(({ ... this.dataStore}).events);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Event has been created');
      }, error => {
        error.displayMessage = 'Could not create event.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error creating Event, please try again later!');
        throw error;
      });
  }

  async update(event: Event) {
    this.processIsSaved();

    if (event.calendarFile && event.calendarFile.file && event.calendarFile.file.name) {
      if (event.calendarFile._id) {
        event.calendarFile = await this.fileService.updateForModel(new File(event.calendarFile.__v, event.calendarFile._id, event.calendarFile.file.name, event.calendarFile.file));
      } else {
        event.calendarFile = await this.fileService.createForModel(new File(0, null, event.calendarFile.file.name, event.calendarFile.file));
      }
    } else if (event.calendarFile && !event.calendarFile.file) {
      event.calendarFile = null;
    }

    return this.http.put<Event>(`${this.base_api_url}/${event._id}`, event)
      .subscribe(data => {
        this.dataStore.events.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.events[i] = data; }
        });

        this.events$.next(({ ... this.dataStore}).events);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Event has been updated');
      }, error => {
        error.displayMessage = 'Could not update event.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error updating Event, please try again later!');
        throw error;
      });
  }

  remove(id: string) {
    return this.http.delete(`${this.base_api_url}/${id}`).subscribe(response => {
      this.dataStore.events.forEach((t, i) => {
        if (t._id === id) { this.dataStore.events.splice(i, 1); }
      });

      this.events$.next(({ ... this.dataStore}).events);
      this.notificationService.notifyInformation('Event has been deleted');
    }, error => {
      error.displayMessage = 'Could not delete event.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error deleting Event, please try again later!');
      throw error;
    });
  }

  search(term: string, ignoreRA = false, publishedOnly = false) {
    if (term === '') {
      return Observable.of([]);
    }

    // 9/30/2022 filter by regional Admin and location
    let url = "";
    if (!ignoreRA && !this.isSA && environment.viewUserCreatedData) {
      url = `${this.base_api_url}/search/${encodeURI(term)}?excludeRelated&authorId=${this.userId}&fromAdmin=true`;
    } else if (!ignoreRA && !this.isSA && !environment.viewUserCreatedData) {
      url =  `${this.base_api_url}/search/${encodeURI(term)}?excludeRelated&location=${this.userLoc}&fromAdmin=true`;
    } else {
      url = `${this.base_api_url}/search/${encodeURI(term)}?excludeRelated&fromAdmin=true`;
    }

    if (publishedOnly) {
      url += "&publishedOnly=true";
    }

    return this.http
      .get<Event[]>(`${url}`)
      .pipe(
        map(response => {
          let data:Event[] = new Array<Event>();
          response.forEach(i => {
              data.push(Event.createFrom(i));
          });
          return data;
        },
      ));
  }

  private processIsSaved() {
    this.isSaved
      .subscribe((value) => {
        console.log("service subscribe - " + value);
        if (value === true)
        { this.isSaved$.next(false); }
      });
  }
}
