import { BehaviorSubject, Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Challenge } from './models/challenge.model'
import { NotificationService } from '../services/notification/notification.service';

@Injectable()
export class ChallengeService {

  private base_api_url: string = 'api/challenge';
  challenges: Observable<Challenge[]>
  private challenges$: BehaviorSubject<Challenge[]>;
  private dataStore: {
    challenges: Challenge[]
  };
  isSaved: Observable<boolean>;
  private isSaved$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private notificationService:NotificationService) {
    this.dataStore = { challenges: [] };
    this.challenges$ = <BehaviorSubject<Challenge[]>>new BehaviorSubject([]);
    this.challenges = this.challenges$.asObservable();

    this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.isSaved = this.isSaved$.asObservable();
  }

  loadAll() {
    let $this = this;
    return this.http.get<Challenge[]>(`${this.base_api_url}?leaveShortcodesAsText=true&fromAdmin=true`, { observe: 'response' }).subscribe(resp => {
      let data:Challenge[] = new Array<Challenge>();
      resp.body.forEach(i => data.push(Challenge.createFrom(i)));
      $this.dataStore.challenges = data;
      $this.challenges$.next(({ ... $this.dataStore}).challenges);
    }, error => {
      error.displayMessage = 'Could not load challenges.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading Challenges, please try again later!');
      throw error;
    });
  }

  loadAllData() {
    return this.http.get<Challenge[]>(`${this.base_api_url}?excludeRelated=true&leaveShortcodesAsText=true&fromAdmin=true`);
  }

  load(id: string) {
    return this.http.get<Challenge>(`${this.base_api_url}/${id}`).subscribe(data => {
      let notFound = true;

      this.dataStore.challenges.forEach((item, index) => {
        if (item._id === data._id) {
          this.dataStore.challenges[index] = data;
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.challenges.push(data);
      }

      this.challenges$.next(({ ... this.dataStore}).challenges);
    }, error => {
      error.displayMessage = 'Could not load challenge.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading Challenges, please try again later!');
      throw error;
    });
  }

  loadData(id: string) {
    return this.http.get<Challenge>(`${this.base_api_url}/${id}?includeBase64=true&leaveShortcodesAsText=true&fromAdmin=true`)
      .map(challenge => {
        if (challenge.startDate) { challenge.startDate = new Date(String(challenge.startDate)); }
        if (challenge.endDate) { challenge.endDate = new Date(String(challenge.endDate)); }

        if (challenge.openDate) { challenge.openDate = new Date(String(challenge.openDate)); }
        if (challenge.closeDate) { challenge.closeDate = new Date(String(challenge.closeDate)); }
        return challenge;
      });
  }

  create(challenge: Challenge) {
    this.processIsSaved();


    let startDate = challenge.startDate;
    //startDate.setHours(9, 0, 0, 0);
    startDate.setUTCHours(9, 0, 0, 0); // 9AM EST
    challenge.startDate = startDate;

    let endDate = challenge.endDate;
    //endDate.setHours(21, 0, 0 , 0);
    endDate.setUTCHours(21, 0, 0, 0); // 9PM EST
    challenge.endDate = endDate;

    // let startDate = challenge.startDate;
    // startDate.setUTCHours(9, 0, 0, 0); // 9AM EST
    // challenge.startDate = startDate;

    // let endDate = challenge.endDate;
    // endDate.setUTCHours(21, 0, 0, 0); // 9PM EST
    // challenge.endDate = endDate;

    return this.http.post<Challenge>(this.base_api_url, challenge).subscribe(data => {
        console.log('successful create');
        this.dataStore.challenges.push(data);
        this.challenges$.next(({ ... this.dataStore}).challenges);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Challenge has been created');
      }, error => {
        error.displayMessage = 'Could not create challenge.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error creating Challenge, please try again later!');
        throw error;
      });
  }

  update(challenge: Challenge) {
    this.processIsSaved();

    let startDate = challenge.startDate;
    console.log("Before startDate:" + challenge.startDate);
    //startDate.setHours(9, 0, 0, 0);
    startDate.setUTCHours(9, 0, 0, 0); // 9AM EST
    challenge.startDate = startDate;
    console.log("After startDate:" + challenge.startDate);

    let endDate = challenge.endDate;
    console.log("Before: endDate:" + challenge.endDate);
    //endDate.setHours(21, 0, 0 , 0);
    endDate.setUTCHours(21, 0, 0, 0); // 9PM EST
    challenge.endDate = endDate;
    console.log("After endDate:" + challenge.endDate);

    return this.http.put<Challenge>(`${this.base_api_url}/${challenge._id}`, challenge)
      .subscribe(data => {
        this.dataStore.challenges.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.challenges[i] = data; }
        });

        this.challenges$.next(({ ... this.dataStore}).challenges);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Challenge has been updated');
      }, error => {
        error.displayMessage = 'Could not update challenge.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error updating Challenge, please try again later!');
        throw error;
      });
  }

  remove(id: string) {
    return this.http.delete(`${this.base_api_url}/${id}`).subscribe(response => {
      this.dataStore.challenges.forEach((t, i) => {
        if (t._id === id) { this.dataStore.challenges.splice(i, 1); }
      });

      this.challenges$.next(({ ... this.dataStore}).challenges);
      this.notificationService.notifyInformation('Challenge has been deleted');
    }, error => {
      error.displayMessage = 'Could not delete challenge.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error deleting Challenge, please try again later!');
      throw error;
    });
  }

  private processIsSaved() {
    this.isSaved
      .subscribe((value) => {
        console.log("service subscribe - " + value);
        if (value === true)
        { this.isSaved$.next(false); }
      });
  }
}
