import * as _ from "lodash";

export class ListUtil {
  static initializeListProperty<T>(o: Object, propertyName: string) {
    const items = _.cloneDeep(o[propertyName]);
    if (o[propertyName] != null) {
      o[propertyName].length = 0;
    }
    else {
      o[propertyName] = new Array<T>();
    }

    return items;
  }

  static pushItems<T>(model: any, propertyName: string, createFrom: Function, items: any[]) {
    model[propertyName] = new Array<T>();
    if (items != null) {
      items.forEach(i => model[propertyName].push(createFrom(i)));
    }

  }
}
