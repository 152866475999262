import { Office } from './office.model';
import { Article } from './article.model';

export class ChallengeName {
    constructor(
        public __v: number = 0,
        public _id: string = null,
        public name: string = null,
        public tagline: string = null,
        public subtype: ChallengeSubtype = null,
        public active: boolean = false,
        ) { }

    static createFrom(o: Object) {
        const model = new ChallengeName();
        return Object.assign(model, o);
    }
}

export class ChallengeSubtype {
    constructor(
        public __v: number = 0,
        public _id: string = null,
        public name: string = null,
        public displayName: string = null,
        ) { }

    static createFrom(o: Object) {
        const model = new ChallengeSubtype();
        return Object.assign(model, o);
    }
}

export class ChallengeType {
    constructor(
        public __v: number = 0,
        public _id: string = null,
        public name: string = null,
        public displayName: string = null,
        public subtypes: ChallengeSubtype[] = null,
        ) { }

    static createFrom(o: Object) {
        const model = new ChallengeType();
        return Object.assign(model, o);
    }
}

export class ChallengeImage {
    constructor(
        public mimetype: string = null,
        public size: number = null,
        public filename: string = null,
        ) { }

    static createFrom(o: Object) {
        const model = new ChallengeType();
        return Object.assign(model, o);
    }
}

export class ChallengeInfographicContent {
    constructor(
        public __v: number = 0,
        public _id: string = null,
        public title: string = null,
        public subtitle: string = null,
        public description: string = null,
        public createdDate: Date = null,
        public imageBase64: string = null,
        public image: ChallengeImage = null,
        public isDelete: boolean = false,
        public uiId: string = null,
        public sortOrder: number = null,
        ) { }

    static createFrom(o: Object) {
        const model = new ChallengeInfographicContent();
        return Object.assign(model, o);
    }
}

export class Challenge {
    constructor(
        public __v: number = 0,
        public _id: string = null,
        public name: string = null,
        public type: ChallengeType = null,
        public subtype: ChallengeSubtype = null,
        public metric: string = null,
        public title: string = null,
        public description: string = null,
        public location: Office = null,
        public startDate: Date = null,
        public endDate: Date = null,
        public openDate: Date = null,
        public closeDate: Date = null,
        public createdDate: Date = null,
        public image: ChallengeImage = null,
        public imageBase64: string = null,
        public imageFill: string = null,
        public fillImage: ChallengeImage = null,
        public article: Article = null,
        public subtitle: string = null,
        public infographicContents: ChallengeInfographicContent[] = null,
        public typeName: string = null,
        public active: boolean = false,
        public defaultGoals: string = null,
        public goalTypeDetails: string = null,
        public fillImageBase64: string = null,
        ) { }

    static createFrom(o: Object) {
        const model = new Challenge();
        return Object.assign(model, o);
    }
}
