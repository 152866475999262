import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

@Directive({
    selector: '[csvFormatValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: CommaValueValidatorDirective, multi: true}]
  })
  export class CommaValueValidatorDirective implements Validator {
    @Input('appForbiddenName') forbiddenName: string;
  
    validate(control: AbstractControl): {[key: string]: any} | null {

        var re = new RegExp("^([0-9])+(,[0-9]+)*$");

        if (re.test(control.value)){
            null;
        }
        return  {'csvInvalid': {value: control.value}};

        //   return this.forbiddenName ? forbiddenNameValidator(new RegExp(this.forbiddenName, 'i'))(control)
        //                             : null;
    }
  }

//   export function forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
//     return (control: AbstractControl): {[key: string]: any} | null => {
//       const forbidden = nameRe.test(control.value);
//       return forbidden ? {'forbiddenName': {value: control.value}} : null;
//     };
//   }

