import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import 'rxjs/add/operator/publish';

export enum NotificationType {
    Default = 'default', 
    Info = 'info', 
    Success = 'success', 
    Warning = 'warning', 
    Error = 'error',
  };
  
export class NotificationMessage {
    constructor(
        public type: NotificationType,
        public title: string,
        public message: string,
    ) { }
  }
  
@Injectable()
export class NotificationService {

  private _notification: BehaviorSubject<NotificationMessage> = new BehaviorSubject(null);
  readonly notification$: Observable<NotificationMessage> = this._notification.asObservable().publish().refCount();

  constructor() {}

  notify(message:NotificationMessage) {
    this._notification.next(message);
    // setTimeout(() => this._notification.next(null), 3000);
  }

  notifyInformation(msg:string){
    this._notification.next(new NotificationMessage(NotificationType.Success, 'Information',msg));
  }

  notifyError(msg:string){
    this._notification.next(new NotificationMessage(NotificationType.Error, 'Error',msg));
  }

  notifyWarning(msg:string){
    this._notification.next(new NotificationMessage(NotificationType.Warning, 'Warning',msg));
  }
}