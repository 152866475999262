import { Office, Region } from "./office.model";
export class Role {
  constructor(
    public __v: number = 0,
    public _id: string = null,
    public name: string = null,
) { }
  
    static createFrom(o: Object) {
      const model = new Role();
      return Object.assign(model, o);
    }
  }

export class User {
  constructor(
    public __v: number = 0,
    public _id: string = null,
    public soeId: string = null,
    public firstName: string = null,
    public lastName: string = null,
    public email: string = null,

    public role: Role = null,
    public location: Region = null) { }
    static createFrom(o: Object) {
      const model = new User();
      return Object.assign(model, o);
    }
  }
