import { BehaviorSubject, Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Region } from './models/office.model'
import { NotificationService } from '../services/notification/notification.service';

@Injectable()
export class RegionService {

  private base_api_url: string = 'api/region';
  regions: Observable<Region[]>
  private regions$: BehaviorSubject<Region[]>;
  private dataStore: {
    regions: Region[]
  };
  isSaved: Observable<boolean>;
  private isSaved$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private notificationService:NotificationService) {
    this.dataStore = { regions: [] };
    this.regions$ = <BehaviorSubject<Region[]>>new BehaviorSubject([]);
    this.regions = this.regions$.asObservable();

    this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.isSaved = this.isSaved$.asObservable();
  }

  loadAll() {
    let $this = this;
    return this.http.get<Region[]>(this.base_api_url, { observe: 'response' }).subscribe(resp => {
      let data:Region[] = new Array<Region>();
      resp.body.forEach(i => data.push(Region.createFrom(i)));
      $this.dataStore.regions = data;
      $this.regions$.next(({ ... $this.dataStore}).regions);
    }, error => {
      error.displayMessage = 'Could not load regions.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading regions, please try again later!');
      throw error;
    });
  }

  loadAllData() {
    return this.http.get<Region[]>(this.base_api_url);
  }

  load(id: string) {
    return this.http.get<Region>(`${this.base_api_url}/${id}`).subscribe(data => {
      let notFound = true;

      this.dataStore.regions.forEach((item, index) => {
        if (item._id === data._id) {
          this.dataStore.regions[index] = data;
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.regions.push(data);
      }

      this.regions$.next(({ ... this.dataStore}).regions);
    }, error => {
      error.displayMessage = 'Could not load region.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading region, please try again later!');
      throw error;
    });
  }

  create(article: Region) {
    this.processIsSaved();

    return this.http.post<Region>(this.base_api_url, article).subscribe(data => {
        console.log('successful create');
        this.dataStore.regions.push(data);
        this.regions$.next(({ ... this.dataStore}).regions);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Region has been created');
      }, error => {
        error.displayMessage = 'Could not create region.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error creating region, please try again later!');
        throw error;
      });
  }

  update(article: Region) {
    this.processIsSaved();

    return this.http.put<Region>(`${this.base_api_url}/${article._id}`, article)
      .subscribe(data => {
        this.dataStore.regions.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.regions[i] = data; }
        });

        this.regions$.next(({ ... this.dataStore}).regions);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Region has been updated');
      }, error => {
        error.displayMessage = 'Could not update article.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error updating region, please try again later!');
        throw error;
      });
  }

  remove(id: string) {
    return this.http.delete(`${this.base_api_url}/${id}`).subscribe(response => {
      this.dataStore.regions.forEach((t, i) => {
        if (t._id === id) { this.dataStore.regions.splice(i, 1); }
      });

      this.regions$.next(({ ... this.dataStore}).regions);
      this.notificationService.notifyInformation('Region has been deleted');
    }, error => {
      error.displayMessage = 'Could not delete region.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error deleting region, please try again later!');
      throw error;
    });
  }

  private processIsSaved() {
    this.isSaved
      .subscribe((value) => {
        console.log("service subscribe - " + value);
        if (value === true)
        { this.isSaved$.next(false); }
      });
  }
}
