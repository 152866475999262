import { Component, OnInit, NgZone, Injector, ApplicationRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AppConfigService } from '../app-config.service';
import { UserService } from '../@core/data/user.service'
import { CookieService } from 'ngx-cookie-service';
import { UserIdleService } from 'angular-user-idle';
import { Observable } from 'rxjs';
import { User } from '../@core/data/models/user.model';

@Component({
  selector: 'citi-login',
  // styleUrls: ['./login.component.scss'],
  styles: [``],
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  //users: any[];
  users$: Observable<User[]>;
  selectedUser: any;
  isProduction: boolean;
  isUAT: boolean;
  ssoRedirect: string;
  prodOldSSO: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appConfigService: AppConfigService,
    private userService: UserService,
    private cookieService: CookieService,
    private userIdle: UserIdleService,
  ) {
    console.log('login constructor');
    //this.users = environment.users;
    this.isProduction = environment.production;
    this.isUAT = environment.uat;
    this.ssoRedirect = environment.SSO_REDIRECT_URL;
    this.prodOldSSO = environment.prodOldSSO;

    if ((!this.isProduction) && (!this.isUAT)) {
      this.users$ = this.userService.users;
  
      this.userService.loadAll();
    }
  }

  ngOnInit(): void {
    console.log('login init');
    (async () => {
      if (!sessionStorage.userInfo) {

        console.log("is production = " + this.isProduction);
        console.log("is uat = " + this.isUAT);

        if ((this.isProduction) || (this.isUAT)) {
          if (!this.prodOldSSO) {  // (this.prodOldSSO != true)
             let lwca_Auth = this.cookieService.get('LWCA_AUTH');
             let lwca_User = this.cookieService.get('LWCA_USER');

             console.log('lwca auth = ' + lwca_Auth);
             console.log('lwca user = ' + lwca_User);

             if ((lwca_Auth != null) && (lwca_Auth != '') 
              && (lwca_User != null) && (lwca_User != '')
               ) {
                
                let thisUser = JSON.parse(lwca_User);
                let fullUser = await this.userService.getCurrent().first().toPromise();

               console.log('set session storage');
               sessionStorage.userInfo = JSON.stringify({
                 token: lwca_Auth,
                 user: fullUser ? fullUser : thisUser
               });
               console.log('session storage = ' + sessionStorage.userInfo);
            
                // let userInfo = await this.userService.validateLivewellToken(lwca_Auth).toPromise();
                // console.log('userInfo = ' + userInfo);
                // if (!userInfo) { throw new Error('Invalid user'); }

                // sessionStorage.userInfo = JSON.stringify(userInfo);
                // console.log('userInfo = ' + JSON.stringify(userInfo));
              }
              
              if (!sessionStorage.userInfo) {
                console.log('session storage = ' + sessionStorage.userInfo);
                console.log('sso redirect = ' + this.ssoRedirect);
                window.location.href = this.ssoRedirect;
              }
            
          }
          else {
            let lwc_Auth = this.cookieService.get('LWC_AUTH');
            console.log('lwc_Auth = ' + lwc_Auth);
            let lwca_Auth = this.cookieService.get('LWCA_AUTH');
            console.log('lwc_Auth = ' + lwca_Auth);

            if ((lwc_Auth != null) && (lwc_Auth != '')) {
              let userInfo = await this.userService.validateLivewellToken(lwc_Auth).toPromise();
              console.log('userInfo = ' + userInfo);
              if (!userInfo) { throw new Error('Invalid user'); }

              sessionStorage.userInfo = JSON.stringify(userInfo);
              console.log('userInfo = ' + JSON.stringify(userInfo));
            }
            else {
              console.log('sso redirect = ' + this.ssoRedirect);
              window.location.href = this.ssoRedirect;              
            }
          }
        }
      }

     
      if (sessionStorage.userInfo) {

        // user logged in at this point, start session timer
        console.log('startWatching');
        this.userIdle.startWatching();


        console.log('session storage = ' + sessionStorage.userInfo);
        console.log('redirect to admin dashboard');
        this.router.navigate(['pages', 'dashboard'], { skipLocationChange: true });
      }
    })();
  }

  onLogin() {
    console.log('login click');
    if (!this.selectedUser) return;
    let soeId = this.selectedUser['soeId'];
    (async () => {
      try {
        let userInfo = await this.userService.authenticate(soeId).toPromise();
        if (!userInfo) { throw new Error('Invalid user'); }
        sessionStorage.userInfo = JSON.stringify(userInfo);

        // user logged in at this point, start session timer
        console.log('startWatching');
        this.userIdle.startWatching();

        this.router.navigate(['pages', 'dashboard'], { skipLocationChange: true });
      }
      catch (err) {
          console.log(err);
          throw err;
      }
    })();
  
  }
}
