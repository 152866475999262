import { BehaviorSubject, Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Notification } from './models/notification.model'

@Injectable()
export class NotificationService {

  private base_api_url: string = 'api/notification';
  notifications: Observable<Notification[]>
  private notifications$: BehaviorSubject<Notification[]>;
  private dataStore: {
    notifications: Notification[]
  };
  isSaved: Observable<boolean>;
  private isSaved$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient) {
    this.dataStore = { notifications: [] };
    this.notifications$ = <BehaviorSubject<Notification[]>>new BehaviorSubject([]);
    this.notifications = this.notifications$.asObservable();

    this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.isSaved = this.isSaved$.asObservable();
  }

  loadAll(fields: string | object = null) {
    let $this = this;
    let queryParam = '';
    if (fields) {
      if (typeof(fields) === 'object') { fields = JSON.stringify(fields); }
      queryParam = `?fields=${encodeURI(fields)}&excludeRelated&fromAdmin=true`;
    } else {
      queryParam = '?fromAdmin=true';
    }
    return this.http.get<Notification[]>(`${this.base_api_url}${queryParam}`, { observe: 'response' }).subscribe(resp => {
      let data:Notification[] = new Array<Notification>();
      resp.body.forEach(i => data.push(Notification.createFrom(i)));
      $this.dataStore.notifications = data;
      $this.notifications$.next(({ ... $this.dataStore}).notifications);
    }, error => {
      error.displayMessage = 'Could not load notifications.';
      console.log(error.displayMessage);
      throw error;
    });
  }

  loadAllData() {
    return this.http.get<Notification[]>(this.base_api_url);
  }

  load(id: string) {
    return this.http.get<Notification>(`${this.base_api_url}/${id}?fromAdmin=true`).subscribe(data => {
      let notFound = true;

      this.dataStore.notifications.forEach((item, index) => {
        if (item._id === data._id) {
          this.dataStore.notifications[index] = data;
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.notifications.push(data);
      }

      this.notifications$.next(({ ... this.dataStore}).notifications);
    }, error => {
      error.displayMessage = 'Could not load notification.';
      console.log(error.displayMessage);
      throw error;
    });
  }

  loadData(id: string) {
    return this.http.get<Notification>(`${this.base_api_url}/${id}?fromAdmin=true`)
    .map(notification => {
      if (notification.schedule.specificDate) { notification.schedule.specificDate = new Date(String(notification.schedule.specificDate)); }
      return notification;
    });
  }

  create(notification: Notification) {
    this.processIsSaved();

    return this.http.post<Notification>(this.base_api_url, notification).subscribe(data => {
        console.log('successful create');
        this.dataStore.notifications.push(data);
        this.notifications$.next(({ ... this.dataStore}).notifications);
        this.isSaved$.next(true);
      }, error => {
        error.displayMessage = 'Could not create notification.';
        console.log(error.displayMessage);
        throw error;
      });
  }

  update(notification: Notification) {
    this.processIsSaved();

    return this.http.put<Notification>(`${this.base_api_url}/${notification._id}`, notification)
      .subscribe(data => {
        this.dataStore.notifications.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.notifications[i] = data; }
        });

        this.notifications$.next(({ ... this.dataStore}).notifications);
        this.isSaved$.next(true);
      }, error => {
        error.displayMessage = 'Could not update notification.';
        console.log(error.displayMessage);
        throw error;
      });
  }

  remove(id: string) {
    return this.http.delete(`${this.base_api_url}/${id}`).subscribe(response => {
      this.dataStore.notifications.forEach((t, i) => {
        if (t._id === id) { this.dataStore.notifications.splice(i, 1); }
      });

      this.notifications$.next(({ ... this.dataStore}).notifications);
    }, error => {
      error.displayMessage = 'Could not delete notification.';
      console.log(error.displayMessage);
      throw error;
    });
  }

  search(term: string) {
    if (term === '') {
      return Observable.of([]);
    }

    return this.http
      .get<Notification[]>(`${this.base_api_url}/search/${encodeURI(term)}`)
      .pipe(
        map(response => {
          return response;
        },
      ));
  }


  private processIsSaved() {
    this.isSaved
      .subscribe((value) => {
        console.log("service subscribe - " + value);
        if (value === true)
        { this.isSaved$.next(false); }
      });
  }
}
