import * as _ from "lodash";
import { BehaviorSubject, Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Article, ArticleCategory } from './models/article.model'
import { query } from "@angular/animations";

import {PaginatedResponse} from "./models/paginatedResponse.model";
import { NotificationService } from "../services/notification/notification.service";
import { currentUserID, currentUserLocation, isSuperAdmin } from "../utils/session";
import { environment } from "../../../environments/environment";

@Injectable()
export class ArticleService {

  private base_api_url: string = 'api/article';
  articles: Observable<Article[]>
  private articles$: BehaviorSubject<Article[]>;
  private dataStore: {
    articles: Article[]
  };
  isSaved: Observable<boolean>;
  private isSaved$: BehaviorSubject<boolean>;
  private userLoc: string;
  private isSA: boolean;
  private userId : string;

  constructor(private http: HttpClient, private notificationService:NotificationService) {
    this.dataStore = { articles: [] };
    this.articles$ = <BehaviorSubject<Article[]>>new BehaviorSubject([]);
    this.articles = this.articles$.asObservable();

    this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.isSaved = this.isSaved$.asObservable();

    this.userLoc = currentUserLocation();
    this.isSA = isSuperAdmin();
    this.userId = currentUserID();
  }

  loadAll(fields: string | object = null) {
    let $this = this;
    let queryParam = '';
    if (fields) {
      if (typeof(fields) === 'object') { fields = JSON.stringify(fields); }
      queryParam = `?fields=${encodeURI(fields)}&excludeRelated&fromAdmin=true`;
    } else {
      queryParam = '?fromAdmin=true';
    }
    
     // 9/30/2022 filter by regional Admin and location
     if(!this.isSA && environment.viewUserCreatedData) {
       queryParam +=  `&authorId=${this.userId}`;
      } else if (!this.isSA && !environment.viewUserCreatedData) {
       queryParam +=  `&location=${this.userLoc}`;
     }
       
    return this.http.get<Article[]>(`${this.base_api_url}${queryParam}`, { observe: 'response' }).subscribe(resp => {
      let data:Article[] = new Array<Article>();
      resp.body.forEach(i => {
          data.push(Article.createFrom(i));
        });
      $this.dataStore.articles = data;
      $this.articles$.next(({ ... $this.dataStore}).articles);
    }, error => {
      error.displayMessage = 'Could not load articles.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading articles, please try again later!');
      throw error;
    });

    // return this.http.get<PaginatedResponse<Article>>(`${this.base_api_url}${queryParam}`, { observe: 'response' }).subscribe(resp => {
    //   let data:Article[] = new Array<Article>();
    //   resp.body.data.forEach(i => data.push(Article.createFrom(i)));
    //   $this.dataStore.articles = data;
    //   $this.articles$.next(({ ... $this.dataStore}).articles);
    // }, error => {
    //   error.displayMessage = 'Could not load articles.';
    //   console.log(error.displayMessage);
    //   throw error;
    // });
  }

  loadAllData() {
    var uri = `${this.base_api_url}?fromAdmin=true&leaveShortcodesAsText=true`;
    return this.http.get<Article[]>(uri);
  }

  load(id: string) {
    return this.http.get<Article>(`${this.base_api_url}/${id}?includeBase64&leaveShortcodesAsText=true`).subscribe(data => {
      let notFound = true;

      this.dataStore.articles.forEach((item, index) => {
        if (item._id === data._id) {
          this.dataStore.articles[index] = data;
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.articles.push(data);
      }

      this.articles$.next(({ ... this.dataStore}).articles);
    }, error => {
      error.displayMessage = 'Could not load article.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading article, please try again later!');
      throw error;
    });
  }

  loadData(id: string) {
    return this.http.get<Article>(`${this.base_api_url}/${id}?includeBase64&leaveShortcodesAsText=true`);
  }

  create(article: Article) {
    this.processIsSaved();

    return this.http.post<Article>(this.base_api_url, article).subscribe(data => {
        console.log('successful create');
        this.dataStore.articles.push(data);
        this.articles$.next(({ ... this.dataStore}).articles);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Article has been created');
      }, error => {
        error.displayMessage = 'Could not create article.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error creating article, please try again later!');
        throw error;
      });
  }

  update(article: Article) {
    this.processIsSaved();

    return this.http.put<Article>(`${this.base_api_url}/${article._id}`, article)
      .subscribe(data => {
        this.dataStore.articles.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.articles[i] = data; }
        });

        this.articles$.next(({ ... this.dataStore}).articles);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Article has been updated');
      }, error => {
        error.displayMessage = 'Could not update article.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error updating article, please try again later!');
        throw error;
      });
  }

  remove(id: string) {
    return this.http.delete(`${this.base_api_url}/${id}`).subscribe(response => {
      this.dataStore.articles.forEach((t, i) => {
        if (t._id === id) { this.dataStore.articles.splice(i, 1); }
      });

      this.articles$.next(({ ... this.dataStore}).articles);
      this.notificationService.notifyInformation('Article has been deleted');
    }, error => {
      error.displayMessage = 'Could not delete article.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error removing article, please try again later!');
      throw error;
    });
  }

  search(term: string, byTitle = false, ignoreRA = false, publishedOnly = false) {

    if (term === '') {
      return Observable.of([]);
    }

    let fields: any = { _id: true, title: true };
    fields = JSON.stringify(fields);

    // 9/30/2022 filter by regional Admin and location
    let queryParam = "";
    if (!ignoreRA && !this.isSA && environment.viewUserCreatedData) {
      queryParam =  `&authorId=${this.userId}`;
    } else if (!ignoreRA && !this.isSA && !environment.viewUserCreatedData) {
      queryParam =  `&location=${this.userLoc}`;
    }

    if (publishedOnly) {
      queryParam += "&publishedOnly=true";
    }

    const url = byTitle ?
    `${this.base_api_url}/search/title/${encodeURI(term)}?fields=${encodeURI(fields)}&excludeRelated&fromAdmin=true${queryParam}` :
    `${this.base_api_url}/search/${encodeURI(term)}?exludeRelated&fromAdmin=true${queryParam}`;

    return this.http
      .get<PaginatedResponse<Article>>(url)
      .pipe(
        map(response => {
          let data:Article[] = new Array<Article>();
          response.data.forEach(i => {
              data.push(i);
          });
          return data ; 
        },
      ));
  }

  searchByTitle(term: string, ignoreRA = false, publishedOnly = false) {
    return this.search(term, true, ignoreRA, publishedOnly);
  }

  private processIsSaved() {
    this.isSaved
      .subscribe((value) => {
        console.log("service subscribe - " + value);
        if (value === true)
        { this.isSaved$.next(false); }
      });
  }
}
