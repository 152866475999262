import { BehaviorSubject, Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Office } from './models/office.model'
import { NotificationService } from '../services/notification/notification.service';
import { currentUserLocation } from '../utils/session';

@Injectable()
export class OfficeService {

  private base_api_url: string = 'api/office';
  offices: Observable<Office[]>
  private offices$: BehaviorSubject<Office[]>;
  private dataStore: {
    offices: Office[]
  };
  isSaved: Observable<boolean>;
  private isSaved$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private notificationService:NotificationService) {
    this.dataStore = { offices: [] };
    this.offices$ = <BehaviorSubject<Office[]>>new BehaviorSubject([]);
    this.offices = this.offices$.asObservable();

    this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.isSaved = this.isSaved$.asObservable();
  }

  loadAll() {
    let $this = this;
    return this.http.get<Office[]>(this.base_api_url, { observe: 'response' }).subscribe(resp => {
      let data:Office[] = new Array<Office>();
      resp.body.forEach(i => data.push(Office.createFrom(i)));
      $this.dataStore.offices = data;
      $this.offices$.next(({ ... $this.dataStore}).offices);
    }, error => {
      error.displayMessage = 'Could not load offices.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading offices, please try again later!');
      throw error;
    });
  }

  loadAllData() {
    return this.http.get<Office[]>(this.base_api_url);
  }

  load(id: string) {
    return this.http.get<Office>(`${this.base_api_url}/${id}`).subscribe(data => {
      let notFound = true;

      this.dataStore.offices.forEach((item, index) => {
        if (item._id === data._id) {
          this.dataStore.offices[index] = data;
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.offices.push(data);
      }

      this.offices$.next(({ ... this.dataStore}).offices);
    }, error => {
      error.displayMessage = 'Could not load office.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading office, please try again later!');
      throw error;
    });
  }

  loadData(id: string) {
    return this.http.get<Office>(`${this.base_api_url}/${id}?fromAdmin=true`);
  }

  create(article: Office) {
    this.processIsSaved();

    return this.http.post<Office>(this.base_api_url, article).subscribe(data => {
        console.log('successful create');
        this.dataStore.offices.push(data);
        this.offices$.next(({ ... this.dataStore}).offices);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Office entry has been created');
      }, error => {
        error.displayMessage = 'Could not create office.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error creating office, please try again later!');
        throw error;
      });
  }

  update(article: Office) {
    this.processIsSaved();

    return this.http.put<Office>(`${this.base_api_url}/${article._id}`, article)
      .subscribe(data => {
        this.dataStore.offices.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.offices[i] = data; }
        });

        this.offices$.next(({ ... this.dataStore}).offices);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Office entry has been updated');
      }, error => {
        error.displayMessage = 'Could not update office.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error updating office, please try again later!');
        throw error;
      });
  }

  remove(id: string) {
    return this.http.delete(`${this.base_api_url}/${id}`).subscribe(response => {
      this.dataStore.offices.forEach((t, i) => {
        if (t._id === id) { this.dataStore.offices.splice(i, 1); }
      });

      this.offices$.next(({ ... this.dataStore}).offices);
      this.notificationService.notifyInformation('Office entry has been deleted');
    }, error => {
      error.displayMessage = 'Could not delete office.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error deleting office, please try again later!');
      throw error;
    });
  }

  search(term: string) {
    if (term === '') {
      return Observable.of([]);
    }

    // let fields: any = { _id: true, title: true };

    // fields = JSON.stringify(fields);
    // let urlParam: string = `?fields=${encodeURI(fields)}&excludeRelated`;
    let urlParam: string = `?excludeRelated&fromAdmin=true`;
    let userLoc = currentUserLocation();
    let url = "";
    if(userLoc != null)
      url = `${this.base_api_url}/searchregion/${encodeURI(userLoc)}/${encodeURI(term)}${urlParam}`;
    else
      url = `${this.base_api_url}/search/${encodeURI(term)}${urlParam}`;
    return this.http
      .get<Office[]>(url)
      .pipe(
        map(response => {
          return response;
        }
      ));
  }

  private processIsSaved() {
    this.isSaved
      .subscribe((value) => {
        console.log("service subscribe - " + value);
        if (value === true)
        { this.isSaved$.next(false); }
      });
  }
}
