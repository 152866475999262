import { Article } from "./article.model";
import { Event } from "./event.model";

export class Document {
  constructor(
    public __v: number = 0,
    public _id: string = null,
    public name: string = null,
    public file: any = null,
    public articles: Article[] = null,
    public events: Event[] = null,
    public createdBy: string = null,
  ) { }
  
  static createFrom(o: Object) {
    const model = new Document();
    return Object.assign(model, o);
  }
}
