import * as _ from "lodash";
import { BehaviorSubject, Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { FAQ } from './models/faq.model'
import { FAQCategory } from './models/faq-category.model'
import { NotificationService, NotificationMessage, NotificationType } from '../../@core/services/notification/notification.service';

@Injectable()
export class FAQCategoryService {

  private base_api_url: string = 'api/faqcategory';
  faqCategories: Observable<FAQCategory[]>
  private faqCategories$: BehaviorSubject<FAQCategory[]>;
  private dataStore: {
    faqCategories: FAQCategory[]
  };
  isSaved: Observable<boolean>;
  private isSaved$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient,private notificationService: NotificationService) {
    this.dataStore = { faqCategories: [] };
    this.faqCategories$ = <BehaviorSubject<FAQCategory[]>>new BehaviorSubject([]);
    this.faqCategories = this.faqCategories$.asObservable();

    this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.isSaved = this.isSaved$.asObservable();
  }

  loadAll() {
    let $this = this;
    return this.http.get<FAQCategory[]>(`${this.base_api_url}`, { observe: 'response' }).subscribe(resp => {
      let data:FAQCategory[] = new Array<FAQCategory>();
      resp.body.forEach(i => data.push(FAQCategory.createFrom(i)));
      $this.dataStore.faqCategories = data;
      $this.faqCategories$.next(({ ... $this.dataStore}).faqCategories);
    }, error => {
      error.displayMessage = 'Could not load faqCategories.';
      this.notificationService.notifyError('Error Loading FAQ Categories, please try again later!');
      console.log(error.displayMessage);
      throw error;
    });
  }

  load(id: string) {
    return this.http.get<FAQCategory>(`${this.base_api_url}/${id}`).subscribe(data => {
      let notFound = true;

      this.dataStore.faqCategories.forEach((item, index) => {
        if (item._id === data._id) {
          this.dataStore.faqCategories[index] = data;
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.faqCategories.push(data);
      }

      this.faqCategories$.next(({ ... this.dataStore}).faqCategories);
    }, error => {
      error.displayMessage = 'Could not load faqcategory.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error Loading FAQ Category, please try again later!');
      throw error;
    });
  }

  loadData(id: string) {
    return this.http.get<FAQCategory>(`${this.base_api_url}/${id}?includeBase64`);
  }

  create(faqcategory: FAQCategory) {
    this.processIsSaved();

    return this.http.post<FAQCategory>(`${this.base_api_url}`, faqcategory).subscribe(data => {
        console.log('successful create');
        this.dataStore.faqCategories.push(data);
        this.faqCategories$.next(({ ... this.dataStore}).faqCategories);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('FAQ Category has been created.');
      }, error => {
        error.displayMessage = 'Could not create faqcategory.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error creating FAQ Category, please try again later!');
        throw error;
      });
  }

  update(faqcategory: FAQCategory) {
    this.processIsSaved();

    return this.http.put<FAQCategory>(`${this.base_api_url}/${faqcategory._id}`, faqcategory)
      .subscribe(data => {
        this.dataStore.faqCategories.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.faqCategories[i] = data; }
        });

        this.faqCategories$.next(({ ... this.dataStore}).faqCategories);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('FAQ Category has been updated.');
      }, error => {
        error.displayMessage = 'Could not update faqcategory.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error updating FAQ Category, please try again later!');
        throw error;
      });
  }

  updateOrder(faqcategories: FAQCategory[]) {
    this.processIsSaved();

    let _toBeSaved = _.map(faqcategories, (category) => {
      let _faqs = _.map(category.faqs, (faq) => new FAQ(null, faq._id, null, null, null, faq.order, null));
      let _category = new FAQCategory(null, category._id, null, category.order, _faqs);
      return _category;
    });
    return this.http.put<FAQCategory[]>(`${this.base_api_url}/order`, _toBeSaved)
      .subscribe(data => { }, error => {
        error.displayMessage = 'Could not update order.';
        console.log(error.displayMessage);
        throw error;
      });
  }

  remove(id: string) {
    return this.http.delete(`${this.base_api_url}/${id}`).subscribe(response => {
      this.dataStore.faqCategories.forEach((t, i) => {
        if (t._id === id) { this.dataStore.faqCategories.splice(i, 1); }
      });

      this.faqCategories$.next(({ ... this.dataStore}).faqCategories);
      this.notificationService.notifyInformation('FAQ Category has been deleted.');
    }, error => {
      error.displayMessage = 'Could not delete faq category.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error deleting FAQ Category, please try again later!');
      throw error;
    });
  }

  createFAQ(faq: FAQ) {
    this.processIsSaved();

    return this.http.post<FAQ>(`api/faq`, faq).subscribe(data => {
        console.log('successful create faq');
        let category = this.dataStore.faqCategories.find((category) => category._id == data.category);
        if (category != null) {
          category.faqs.push(data);
          this.faqCategories$.next(({ ... this.dataStore}).faqCategories);
          this.isSaved$.next(true);
          this.notificationService.notifyInformation('FAQ has been created. Please note that there may be a dealy before you can view it on the page.');
        }
      }, error => {
        error.displayMessage = 'Could not create faq category.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error Creating FAQ, please try again later!');
        throw error;
      });
  }

  updateFAQ(faq: FAQ) {
    this.processIsSaved();

    return this.http.put<FAQ>(`api/faq/${faq._id}`, faq)
      .subscribe(data => {
        let category = this.dataStore.faqCategories.find((category) => category._id == data.category);
        if (category != null) {
          let index = category.faqs.findIndex((faq) => faq._id == data._id);
          if (index > -1) {
            category.faqs[index] = data;
          }
        }

        this.faqCategories$.next(({ ... this.dataStore}).faqCategories);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('FAQ has been updated. Please note that there may be a dealy before you can view it on the page.');
      }, error => {
        error.displayMessage = 'Could not update faqcategory.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error updating FAQ, please try again later!');
        throw error;
      });
  }

  removeFAQ(id: string) {
    return this.http.delete(`api/faq/${id}`).subscribe(response => {
      this.dataStore.faqCategories.forEach((t, i) => {
        let index = t.faqs.findIndex((faq) => faq._id == id);
        if (index > -1) { t.faqs.splice(index, 1); }
      });

      this.faqCategories$.next(({ ... this.dataStore}).faqCategories);
      this.notificationService.notifyInformation('FAQ has been deleted');
    }, error => {
      error.displayMessage = 'Could not delete faqcategory.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error deleting FAQ, please try again later!');
      throw error;
    });
  }

  private processIsSaved() {
    this.isSaved
      .subscribe((value) => {
        console.log("service subscribe - " + value);
        if (value === true)
        { this.isSaved$.next(false); }
      });
  }
}
