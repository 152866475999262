export class Region {
    constructor(
      public __v: number = 0,
      public _id: string = null,
      public abbreviation: string = null,
      public name: string = null,
      public countries: Country[] = null,
      ) { }
    
      static createFrom(o: Object) {
        const model = new Region();
        return Object.assign(model, o);
    }
}

export class Country {
    constructor(
      public __v: number = 0,
      public _id: string = null,
      public abbreviation: string = null,
      public name: string = null,
      public states: State[] = null,
      ) { }
    
      static createFrom(o: Object) {
        const model = new Country();
        return Object.assign(model, o);
    }
}

export class State {
    constructor(
      public __v: number = 0,
      public _id: string = null,
      public abbreviation: string = null,
      public name: string = null,
      ) { }
    
      static createFrom(o: Object) {
        const model = new State();
        return Object.assign(model, o);
    }
}

export class Office {
    constructor(
      public __v: number = 0,
      public _id: string = null,
      public name: string = null,
      public region: Region = null,
      public country: Country = null,
      public state: State = null,
      public city: string = null,
      ) { }
    
      static createFrom(o: Object) {
        const model = new Office();
        return Object.assign(model, o);
      }
  }
  