import { BehaviorSubject, Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ChallengeName } from './models/challenge.model'

@Injectable()
export class ChallengeNameService {

  private base_api_url: string = 'api/challengename';
  challengeNames: Observable<ChallengeName[]>
  private challengeNames$: BehaviorSubject<ChallengeName[]>;
  private dataStore: {
    challengeNames: ChallengeName[]
  };
  isSaved: Observable<boolean>;
  private isSaved$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient) {
    this.dataStore = { challengeNames: [] };
    this.challengeNames$ = <BehaviorSubject<ChallengeName[]>>new BehaviorSubject([]);
    this.challengeNames = this.challengeNames$.asObservable();

    this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.isSaved = this.isSaved$.asObservable();
  }

  loadAll() {
    let $this = this;
    return this.http.get<ChallengeName[]>(this.base_api_url, { observe: 'response' }).subscribe(resp => {
      let data:ChallengeName[] = new Array<ChallengeName>();
      resp.body.forEach(i => data.push(ChallengeName.createFrom(i)));
      $this.dataStore.challengeNames = data;
      $this.challengeNames$.next(({ ... $this.dataStore}).challengeNames);
    }, error => {
      error.displayMessage = 'Could not load challengeNames.';
      console.log(error.displayMessage);
      throw error;
    });
  }

  loadAllData() {
    return this.http.get<ChallengeName[]>(this.base_api_url);
  }

  load(id: string) {
    return this.http.get<ChallengeName>(`${this.base_api_url}/${id}`).subscribe(data => {
      let notFound = true;

      this.dataStore.challengeNames.forEach((item, index) => {
        if (item._id === data._id) {
          this.dataStore.challengeNames[index] = data;
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.challengeNames.push(data);
      }

      this.challengeNames$.next(({ ... this.dataStore}).challengeNames);
    }, error => {
      error.displayMessage = 'Could not load challengeName.';
      console.log(error.displayMessage);
      throw error;
    });
  }

  loadData(id: string) {
    return this.http.get<ChallengeName>(`${this.base_api_url}/${id}`);
  }

  create(article: ChallengeName) {
    this.processIsSaved();

    return this.http.post<ChallengeName>(this.base_api_url, article).subscribe(data => {
        console.log('successful create');
        this.dataStore.challengeNames.push(data);
        this.challengeNames$.next(({ ... this.dataStore}).challengeNames);
        this.isSaved$.next(true);
      }, error => {
        error.displayMessage = 'Could not create challengeName.';
        console.log(error.displayMessage);
        throw error;
      });
  }

  update(article: ChallengeName) {
    this.processIsSaved();

    return this.http.put<ChallengeName>(`${this.base_api_url}/${article._id}`, article)
      .subscribe(data => {
        this.dataStore.challengeNames.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.challengeNames[i] = data; }
        });

        this.challengeNames$.next(({ ... this.dataStore}).challengeNames);
        this.isSaved$.next(true);
      }, error => {
        error.displayMessage = 'Could not update challengeName.';
        console.log(error.displayMessage);
        throw error;
      });
  }

  remove(id: string) {
    return this.http.delete(`${this.base_api_url}/${id}`).subscribe(response => {
      this.dataStore.challengeNames.forEach((t, i) => {
        if (t._id === id) { this.dataStore.challengeNames.splice(i, 1); }
      });

      this.challengeNames$.next(({ ... this.dataStore}).challengeNames);
    }, error => {
      error.displayMessage = 'Could not delete challengeName.';
      console.log(error.displayMessage);
      throw error;
    });
  }

  private processIsSaved() {
    this.isSaved
      .subscribe((value) => {
        console.log("service subscribe - " + value);
        if (value === true)
        { this.isSaved$.next(false); }
      });
  }
}
