import * as _ from "lodash";
import { Article } from './article.model';
import { Challenge } from './challenge.model';
import { ListUtil } from './model.list.util';
import { LandingSlide } from "./landingSlide.model";

export class PageSection {
  constructor(
    public __v: number = 0,
    public _id: string = null,
    public name: string = null,
    public description: string = null,
    public order: number = 0,
    public subItems: any[] = null,
    ) { }
  
    static createFrom(o: Object) {
      const model = new PageSection();
      
      const isFeaturedArticles = _.endsWith(o['name'], '-featured-articles');
      const isFeaturedChallenge = _.endsWith(o['name'], '-feature-challenge');

      let subItems: any[] = null;

      if (isFeaturedArticles) { subItems = ListUtil.initializeListProperty<Article>(o, 'subItems'); }
      else if (isFeaturedChallenge) { subItems = ListUtil.initializeListProperty<Challenge>(o, 'subItems'); }

      Object.assign(model, o);

      if (isFeaturedArticles) { ListUtil.pushItems<Article>(model, 'subItems', (i) => Article.createFrom(i), subItems); }
      else if (isFeaturedChallenge) { ListUtil.pushItems<Challenge>(model, 'subItems', (i) => Challenge.createFrom(i), subItems); }

      return model;
    }
}

export class Page {
 
    constructor(
      public __v: number = 0,
      public _id: string = null,
      public name: string = null,
      public description: string = null,
      public title: string = null,
      public subtitle: string = null,
      public subtitle2: string = null,
      public imageBase64: string = null,
      public sections: PageSection[] = null,
      public customs: any[] = null,
      public dynamic: any[] = null,
      public slides: LandingSlide[] = null,
      public featuredChallenge: any = null,
      public featuredChallengeType: string = null,
          ) { }
    
      static createFrom(o: Object) {
        const model = new Page();
        const sections = ListUtil.initializeListProperty<PageSection>(o, 'sections');

        Object.assign(model, o);

        ListUtil.pushItems<PageSection>(model, 'sections', (i) => PageSection.createFrom(i), sections);
    
        return model;
      }
  }

  export class LandingNavigationType {
    constructor(
      public name: string = null,
      public displayName: string = null

          ) { }
    
      // static createFrom(o: Object) {
      //   const model = new Page();
 
      //   Object.assign(model, o);
   
      //   return model;
      // }
  }

  export class LandingNavigation {
    constructor(
      public type: string = null,
      public display: string = null,
      public id: string = null,
          ) { }
    
      static createFrom(o: Object): LandingNavigation {
        const model = new LandingNavigation();
 
        Object.assign(model, o);
   
        return model;
      }
  }
  