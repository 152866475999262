import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../@core/data/user.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { environment } from '../../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'ngx-header',
  // styleUrls: ['./header.component.scss'],
  styles: [``],
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {


  @Input() position = 'normal';

  user: any;

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

  isProduction: boolean;
  isUAT: boolean;
  prodOldSSO: boolean;
  ssoLogoutRedirect: string;
  cookieDomain: string;

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private analyticsService: AnalyticsService,
    private userService: UserService,
    private cookieService: CookieService
  ) {
    this.isProduction = environment.production;
    this.isUAT = environment.uat;
    this.prodOldSSO = environment.prodOldSSO;
    this.ssoLogoutRedirect = environment.SSO_LOGOUT_REDIRECT_URL;
    this.cookieDomain = environment.cookieDomain;
  }

  ngOnInit() {
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  logout() {
    if ((this.isProduction) || (this.isUAT)) {
      // const expire = new Date(1970, 1, 1, 0, 0, 0, 0);
      // sessionStorage.removeItem("userInfo");
      // if (this.prodOldSSO != true) {
      //   this.cookieService.set('LWCA_AUTH', null, expire);
      //   this.cookieService.set('LWCA_USER', null, expire);
      //   this.cookieService.delete('LWCA_AUTH', '/' , this.cookieDomain);
      //   this.cookieService.delete('LWCA_USER', '/' , this.cookieDomain);
      // }
      // else {
      //   this.cookieService.set('LWC_AUTH', null, expire);
      //   this.cookieService.delete('LWC_AUTH', '/' , this.cookieDomain);
      // }
      // console.log('sso redirect = ' + this.ssoLogoutRedirect);
      // window.location.href = this.ssoLogoutRedirect;
      (async () => {
        await this.userService.logout().toPromise();
        sessionStorage.removeItem("userInfo");
        const expire = new Date(1970, 1, 1, 0, 0, 0, 0);
        this.cookieService.set('LWCA_AUTH', null, expire);
        this.cookieService.set('LWCA_USER', null, expire);
        this.cookieService.delete('LWCA_AUTH', '/' , this.cookieDomain);
        this.cookieService.delete('LWCA_USER', '/' , this.cookieDomain);
       
        window.location.href = this.ssoLogoutRedirect;
      })();
    } else {
      (async () => {
        //await this.userService.logout().toPromise();
        sessionStorage.removeItem("userInfo");
        const expire = new Date(1970, 1, 1, 0, 0, 0, 0);
        this.cookieService.set('LWCA_AUTH', null, expire);
        this.cookieService.set('LWCA_USER', null, expire);
        this.cookieService.delete('LWCA_AUTH', '/' , this.cookieDomain);
        this.cookieService.delete('LWCA_USER', '/' , this.cookieDomain);
       
        window.location.href = "/";
      })();
    }
  }
}
