import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserService } from './user.service';
import { RoleService } from './role.service';
import { ElectricityService } from './electricity.service';
import { StateService } from './state.service';
import { SmartTableService } from './smart-table.service';
import { PlayerService } from './player.service';
import { FAQService } from './faq.service';
import { FAQCategoryService } from './faq-category.service';
import { ArticleService } from './article.service';
import { ArticleCategoryService } from './article-category.service';
import { LanguageService } from './language.service';
import { DocumentService } from './document.service';
import { FileService } from './file.service';
import { EventService } from './event.service';
import { OfficeService } from './office.service';
import { RegionService } from './region.service';
import { RegistrationService } from './registration.service';
import { ChallengeService } from './challenge.service';
import { ReportService } from './report.service';
import { ChallengeTypeService } from './challenge-type.service';
import { ChallengeSubtypeService } from './challenge-subtype.service';
import { ChallengeNameService } from './challenge-name.service';
import { PageService } from './page.service';
import { NotificationService } from './notification.service';
import { NotificationTypeService } from './notification-type.service';
import { TimeZoneService } from './timeZone.service';
import { EventReportService } from './event-report.service';
import { LandingSlideService } from './landing-slide.service'; 
 
const SERVICES = [
  UserService,
  RoleService,
  ElectricityService,
  StateService,
  SmartTableService,
  PlayerService,
  FAQService,
  FAQCategoryService,
  ArticleService,
  ArticleCategoryService,
  LanguageService,
  DocumentService,
  FileService,
  EventService,
  OfficeService,
  RegionService,
  RegistrationService,
  ChallengeService,
  ReportService,
  EventReportService,
  ChallengeTypeService,
  ChallengeSubtypeService,
  ChallengeNameService,
  PageService,
  NotificationService,
  NotificationTypeService,
  TimeZoneService,
  LandingSlideService
];

@NgModule({
  imports: [CommonModule],
  providers: [...SERVICES],
})
export class DataModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: DataModule,
      providers: [...SERVICES],
    };
  }
}
