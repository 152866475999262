import { BehaviorSubject, Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Role } from './models/user.model'
import { NotificationService } from '../services/notification/notification.service';

@Injectable()
export class RoleService {

  private base_api_url: string = 'api/role';
  roles: Observable<Role[]>
  private roles$: BehaviorSubject<Role[]>;
  private dataStore: {
    roles: Role[]
  };
  isSaved: Observable<boolean>;
  private isSaved$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private notificationService:NotificationService) {
    this.dataStore = { roles: [] };
    this.roles$ = <BehaviorSubject<Role[]>>new BehaviorSubject([]);
    this.roles = this.roles$.asObservable();

    this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.isSaved = this.isSaved$.asObservable();
  }

  loadAll() {
    let $this = this;
    return this.http.get<Role[]>(this.base_api_url, { observe: 'response' }).subscribe(resp => {
      let data:Role[] = new Array<Role>();
      resp.body.forEach(i => data.push(Role.createFrom(i)));
      $this.dataStore.roles = data;
      $this.roles$.next(({ ... $this.dataStore}).roles);
    }, error => {
      error.displayMessage = 'Could not load roles.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading roles, please try again later!');
      throw error;
    });
  }

  loadAllData() {
    return this.http.get<Role[]>(this.base_api_url);
  }

  load(id: string) {
    return this.http.get<Role>(`${this.base_api_url}/${id}`).subscribe(data => {
      let notFound = true;

      this.dataStore.roles.forEach((item, index) => {
        if (item._id === data._id) {
          this.dataStore.roles[index] = data;
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.roles.push(data);
      }

      this.roles$.next(({ ... this.dataStore}).roles);
    }, error => {
      error.displayMessage = 'Could not load role.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading role, please try again later!');
      throw error;
    });
  }

  loadData(id: string) {
    return this.http.get<Role>(`${this.base_api_url}/${id}`);
  }

  create(role: Role) {
    this.processIsSaved();

    return this.http.post<Role>(this.base_api_url, role).subscribe(data => {
        console.log('successful create');
        this.dataStore.roles.push(data);
        this.roles$.next(({ ... this.dataStore}).roles);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Role has been created');
      }, error => {
        error.displayMessage = 'Could not create role.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error creating role, please try again later!');
        throw error;
      });
  }

  update(role: Role) {
    this.processIsSaved();

    return this.http.put<Role>(`${this.base_api_url}/${role._id}`, role)
      .subscribe(data => {
        this.dataStore.roles.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.roles[i] = data; }
        });

        this.roles$.next(({ ... this.dataStore}).roles);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Role has been updated');
      }, error => {
        error.displayMessage = 'Could not update role.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error updating role, please try again later!');
        throw error;
      });
  }

  remove(id: string) {
    return this.http.delete(`${this.base_api_url}/${id}`).subscribe(response => {
      this.dataStore.roles.forEach((t, i) => {
        if (t._id === id) { this.dataStore.roles.splice(i, 1); }
      });

      this.roles$.next(({ ... this.dataStore}).roles);
      this.notificationService.notifyInformation('Role has been deleted');
    }, error => {
      error.displayMessage = 'Could not delete role.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error deleting role, please try again later!');
      throw error;
    });
  }

  private processIsSaved() {
    this.isSaved
      .subscribe((value) => {
        console.log("service subscribe - " + value);
        if (value === true)
        { this.isSaved$.next(false); }
      });
  }
}
