import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { TimeZone } from './models/timeZone.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TimeZoneService {
  private base_api_url: string = 'api/timezone';

  constructor(private http: HttpClient) {
  }

  loadAllData(): Observable<TimeZone[]> {
    return this.http.get<TimeZone[]>(this.base_api_url);
  }
}