export class File {
  constructor(
    public __v: number = 0,
    public _id: string = null,
    public name: string = null,
    public file: any = null
  ) { }
  
  static createFrom(o: Object) {
    const model = new File();
    return Object.assign(model, o);
  }
}
