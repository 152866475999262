import { HttpClient } from '@angular/common/http';
import { Component, OnInit, NgZone, Injector, ApplicationRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AppConfigService } from '../app-config.service';
import { UserService } from '../@core/data/user.service'
import { CookieService } from 'ngx-cookie-service';
import { UserIdleService } from 'angular-user-idle';
import { User } from '../@core/data/models/user.model';
import { NbSpinnerService } from '@nebular/theme';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {

  constructor(private spinner$: NbSpinnerService) { }

  ngOnInit() {
    this.spinner$.load();
  }

}
