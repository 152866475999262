

export class Language {
  constructor(
    public __v: number = 0,
    public _id: string = null,
    public name: string = null,
    public allArticlesTranslation: string = null,
    public isActive: boolean = null,
    public createdDate: Date = null,
    public createdBy: string = null,
    public updatedDate: Date = null,
    public updatedBy: string = null,
    ) { }

    static createFrom(o: Object) {
      const model = new Language();
      return Object.assign(model, o);
    }
}