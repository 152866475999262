
import { of as observableOf,  Observable } from 'rxjs';
import { BehaviorSubject, Observer } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { User } from '../data/models/user.model'
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NotificationService } from '../services/notification/notification.service';


@Injectable()
export class UserService {

  private base_api_url: string = 'api/user';
  users: Observable<User[]>
  private users$: BehaviorSubject<User[]>;
  private dataStore: {
    users: User[]
  };
  isSaved: Observable<boolean>;
  private isSaved$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private notificationService:NotificationService) {
    this.dataStore = { users: [] };
    this.users$ = <BehaviorSubject<User[]>>new BehaviorSubject([]);
    this.users = this.users$.asObservable();

    this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.isSaved = this.isSaved$.asObservable();
  }

  authenticate(soeId: string) {
    return this.http.post<string>(`api/authenticate`, { soeId: soeId });
  }

  logout() {
    return this.http.post(`api/logout`, {});
  }

  loadAll() {
    let $this = this;
    return this.http.get<User[]>(this.base_api_url, { observe: 'response' }).subscribe(resp => {
      let data:User[] = new Array<User>();
      resp.body.forEach(i => data.push(User.createFrom(i)));
      $this.dataStore.users = data;
      $this.users$.next(({ ... $this.dataStore}).users);
    }, error => {
      error.displayMessage = 'Could not load users.';
      console.log(error.displayMessage);
      throw error;
    });
  }

  loadAllData() {
    return this.http.get<User[]>(this.base_api_url);
  }

  load(id: string) {
    return this.http.get<User>(`${this.base_api_url}/${id}`).subscribe(data => {
      let notFound = true;

      this.dataStore.users.forEach((item, index) => {
        if (item._id === data._id) {
          this.dataStore.users[index] = data;
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.users.push(data);
      }

      this.users$.next(({ ... this.dataStore}).users);
    }, error => {
      error.displayMessage = 'Could not load user.';
      console.log(error.displayMessage);
      throw error;
    });
  }

  loadData(id: string) {
    return this.http.get<User>(`${this.base_api_url}/${id}`);
  }

  getCurrent() {
    return this.http.get<User>(`${this.base_api_url}/current`);
  }

  create(article: User) {
    this.processIsSaved();

    return this.http.post<User>(this.base_api_url, article).subscribe(data => {
        console.log('successful create');
        this.dataStore.users.push(data);
        this.users$.next(({ ... this.dataStore}).users);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('User entry has been created');
      }, error => {
        error.displayMessage = 'Could not create user.';
        console.log(error.displayMessage);
        throw error;
      });
  }

  update(article: User) {
    this.processIsSaved();

    return this.http.put<User>(`${this.base_api_url}/${article._id}`, article)
      .subscribe(data => {
        this.dataStore.users.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.users[i] = data; }
        });

        this.users$.next(({ ... this.dataStore}).users);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('User entry has been updated');
      }, error => {
        error.displayMessage = 'Could not update user.';
        console.log(error.displayMessage);
        throw error;
      });
  }

  remove(id: string) {
    return this.http.delete(`${this.base_api_url}/${id}`).subscribe(response => {
      this.dataStore.users.forEach((t, i) => {
        if (t._id === id) { this.dataStore.users.splice(i, 1); }
      });

      this.users$.next(({ ... this.dataStore}).users);
      this.notificationService.notifyInformation('User entry has been deleted');
    }, error => {
      error.displayMessage = 'Could not delete user.';
      console.log(error.displayMessage);
      throw error;
    });
  }

  private processIsSaved() {
    this.isSaved
      .subscribe((value) => {
        if (value === true)
        { this.isSaved$.next(false); }
      });
  }

  validateLivewellToken(token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        //'Authorization': `Bearer ${token}`
        'lwc-ident': token
      })
    };
    return this.http.get<any>('api/validateLivewellToken', httpOptions);
  }
}
