import * as _ from "lodash";
import { BehaviorSubject, Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Document } from './models/document.model'
import { NotificationService } from '../services/notification/notification.service';
import { currentUserID, currentUserLocation, isSuperAdmin } from '../utils/session';
import { environment } from '../../../environments/environment';
import { PaginatedResponse } from "./models/paginatedResponse.model";

@Injectable()
export class DocumentService {

  private base_api_url: string = 'api/document';
  documents: Observable<Document[]>
  documentsAll: Observable<Document[]>
  private documents$: BehaviorSubject<Document[]>;
  private documentsAll$: BehaviorSubject<Document[]>;
  private dataStore: {
    documents: Document[];
    documentsAll: Document[];
  };
  isSaved: Observable<boolean>;
  private isSaved$: BehaviorSubject<boolean>;
  private isSA: boolean;
  private userId : string;

  constructor(private http: HttpClient, private notificationService:NotificationService) {
    this.dataStore = { documents: [], documentsAll: [] };
    this.documents$ = <BehaviorSubject<Document[]>>new BehaviorSubject([]);
    this.documentsAll$ = <BehaviorSubject<Document[]>>new BehaviorSubject([]);
    this.documents = this.documents$.asObservable();
    this.documentsAll = this.documentsAll$.asObservable();

    this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.isSaved = this.isSaved$.asObservable();
    this.isSA = isSuperAdmin();
    this.userId = currentUserID();
  }

  loadAll() {
    let $this = this;

    // 9/30/2022 filter by regional Admin and location
    let url = "";
    if (!this.isSA) { // && environment.viewUserCreatedData   -- Only let RA users edit their own documents regardless of flag
      url = "?fromAdmin=true" + "&authorId=" + this.userId;
    } else {
      url = "?fromAdmin=true";
    }

    return this.http.get<Document[]>(this.base_api_url + url, { observe: 'response' }).subscribe(resp => {
      let data:Document[] = new Array<Document>();
      resp.body.forEach(i => {
        data.push(Document.createFrom(i));
      });
      $this.dataStore.documents = data;
      $this.documents$.next(({ ... $this.dataStore}).documents);
    }, error => {
      error.displayMessage = 'Could not load documents.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading documents, please try again later!');
      throw error;
    });
  }
  
  loadAllNotRA() {
    let $this = this;
    return this.http.get<Document[]>(this.base_api_url + "?fromAdmin=true", { observe: 'response' }).subscribe(resp => {
      let data:Document[] = new Array<Document>();
      resp.body.forEach(i => {
          data.push(Document.createFrom(i));
      });
      $this.dataStore.documentsAll = data;
      $this.documentsAll$.next(({ ... $this.dataStore}).documentsAll);
    }, error => {
      error.displayMessage = 'Could not load documents.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading documents, please try again later!');
      throw error;
    });
  }

  loadAllData() {
    return this.http.get<Document[]>(this.base_api_url + "?fromAdmin=true");
  }

  load(id: string) {
    return this.http.get<Document>(`${this.base_api_url}/${id}?fromAdmin=true`).subscribe(data => {
      let notFound = true;

      this.dataStore.documents.forEach((item, index) => {
        if (item._id === data._id) {
          this.dataStore.documents[index] = data;
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.documents.push(data);
      }

      this.documents$.next(({ ... this.dataStore}).documents);
    }, error => {
      error.displayMessage = 'Could not load document.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading document, please try again later!');
      throw error;
    });
  }

  loadData(id: string) {
    return this.http.get<Document>(`${this.base_api_url}/${id}?fromAdmin=true`);
  }

  createFormData(document: Document) {
    if (document.file && document.file.name) {
      const formData = new FormData();
      formData.append("file", document.file, document.file.name);
      formData.append("name", document.name);
      return formData;
    } else {
      return document;
    }
  }

  create(document: Document) {
    this.processIsSaved();

    const docToPost = this.createFormData(document);

    return this.http.post<Document>(this.base_api_url, docToPost).subscribe(data => {
        data.articles = [];
        data.events = [];
        console.log('successful create');
        this.dataStore.documents.push(data);
        this.documents$.next(({ ... this.dataStore}).documents);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Document has been created');
      }, error => {
        error.displayMessage = 'Could not create document.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error creating document, please try again later!');
        throw error;
      });
  }

  update(document: Document) {
    this.processIsSaved();

    const docToPost = this.createFormData(document);

    return this.http.put<Document>(`${this.base_api_url}/${document._id}`, docToPost)
      .subscribe(data => {
        this.dataStore.documents.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.documents[i] = data; }
        });

        this.documents$.next(({ ... this.dataStore}).documents);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Document has been updated');
      }, error => {
        error.displayMessage = 'Could not update document.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error updating document, please try again later!');
        throw error;
      });
  }

  remove(id: string) {
    return this.http.delete(`${this.base_api_url}/${id}`).subscribe(response => {
      this.dataStore.documents.forEach((t, i) => {
        if (t._id === id) { this.dataStore.documents.splice(i, 1); }
      });

      this.documents$.next(({ ... this.dataStore}).documents);
      this.notificationService.notifyInformation('Document has been deleted');
    }, error => {
      error.displayMessage = 'Could not delete document.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error deleting document, please try again later!');
      throw error;
    });
  }

  
  search(term: string, byName = false, ignoreRA = false) {

    if (term === '') {
      return Observable.of([]);
    }

    let fields: any = { _id: true, name: true, file: true };
    fields = JSON.stringify(fields);

    // 9/30/2022 filter by regional Admin and location
    let urlRA = "";
    if (!ignoreRA && !this.isSA && environment.viewUserCreatedData) {
      urlRA = "&authorId=" + this.userId;
    }

    const url = byName ?
    `${this.base_api_url}/search/name/${encodeURI(term)}?fields=${encodeURI(fields)}&excludeRelated&fromAdmin=true${urlRA}` :
    `${this.base_api_url}/search/${encodeURI(term)}?exludeRelated&fromAdmin=true${urlRA}`;

    return this.http
      .get<PaginatedResponse<Document>>(url)
      .pipe(
        map(response => {
          let data:Document[] = new Array<Document>();
          response.data.forEach(i => {
              data.push(i);
          });
          return data ; 
        },
      ));
  }

  searchByName(term: string, ignoreRA = false) {
    return this.search(term, true, ignoreRA);
  }

  private processIsSaved() {
    this.isSaved
      .subscribe((value) => {
        console.log("service subscribe - " + value);
        if (value === true)
        { this.isSaved$.next(false); }
      });
  }
}
