import { BehaviorSubject, Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ArticleCategory } from './models/article.model'
import { NotificationService } from '../services/notification/notification.service';

@Injectable()
export class ArticleCategoryService {

  private base_api_url: string = 'api/articleCategory';
  articleCategories: Observable<ArticleCategory[]>
  private articleCategories$: BehaviorSubject<ArticleCategory[]>;
  private dataStore: {
    articleCategories: ArticleCategory[]
  };
  isSaved: Observable<boolean>;
  private isSaved$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private notificationService:NotificationService) {
    this.dataStore = { articleCategories: [] };
    this.articleCategories$ = <BehaviorSubject<ArticleCategory[]>>new BehaviorSubject([]);
    this.articleCategories = this.articleCategories$.asObservable();

    this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.isSaved = this.isSaved$.asObservable();
  }

  loadAll() {
    let $this = this;
    return this.http.get<ArticleCategory[]>(`${this.base_api_url}?fromAdmin=true`, { observe: 'response' }).subscribe(resp => {
      let data:ArticleCategory[] = new Array<ArticleCategory>();
      resp.body.forEach(i => data.push(ArticleCategory.createFrom(i)));
      $this.dataStore.articleCategories = data;
      $this.articleCategories$.next(({ ... $this.dataStore}).articleCategories);
    }, error => {
      error.displayMessage = 'Could not load articleCategories.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading article categories, please try again later!');
      throw error;
    });
  }

  loadAllData() {
    return this.http.get<ArticleCategory[]>(`${this.base_api_url}?fromAdmin=true`);
  }

  load(id: string) {
    return this.http.get<ArticleCategory>(`${this.base_api_url}/${id}`).subscribe(data => {
      let notFound = true;

      this.dataStore.articleCategories.forEach((item, index) => {
        if (item._id === data._id) {
          this.dataStore.articleCategories[index] = data;
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.articleCategories.push(data);
      }

      this.articleCategories$.next(({ ... this.dataStore}).articleCategories);
    }, error => {
      error.displayMessage = 'Could not load article.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading article category, please try again later!');
      throw error;
    });
  }

  loadData(id: string) {
    return this.http.get<ArticleCategory>(`${this.base_api_url}/${id}`);
  }

  create(article: ArticleCategory) {
    this.processIsSaved();

    return this.http.post<ArticleCategory>(this.base_api_url, article).subscribe(data => {
        console.log('successful create');
        this.dataStore.articleCategories.push(data);
        this.articleCategories$.next(({ ... this.dataStore}).articleCategories);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Article Category has been created');
      }, error => {
        error.displayMessage = 'Could not create article category.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error creating article category, please try again later!');
        throw error;
      });
  }

  update(article: ArticleCategory) {
    this.processIsSaved();

    return this.http.put<ArticleCategory>(`${this.base_api_url}/${article._id}`, article)
      .subscribe(data => {
        this.dataStore.articleCategories.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.articleCategories[i] = data; }
        });

        this.articleCategories$.next(({ ... this.dataStore}).articleCategories);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Article Category has been updated');
      }, error => {
        error.displayMessage = 'Could not update article category.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error updating article category, please try again later!');
        throw error;
      });
  }

  remove(id: string) {
    return this.http.delete(`${this.base_api_url}/${id}`).subscribe(response => {
      this.dataStore.articleCategories.forEach((t, i) => {
        if (t._id === id) { this.dataStore.articleCategories.splice(i, 1); }
      });

      this.articleCategories$.next(({ ... this.dataStore}).articleCategories);
      this.notificationService.notifyInformation('Article Category has been deleted');
    }, error => {
      error.displayMessage = 'Could not delete article category.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error deleting article category, please try again later!');
      throw error;
    });
  }

  private processIsSaved() {
    this.isSaved
      .subscribe((value) => {
        console.log("service subscribe - " + value);
        if (value === true)
        { this.isSaved$.next(false); }
      });
  }
}
