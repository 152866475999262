export class FAQ {
  constructor(
    public __v: number = 0,
    public _id: string = null,
    public question: string = null,
    public answer: string = null,
    public createdDate: Date = null,
    public order: number = 0,
    public category: string = null) { }
  
    static createFrom(o: Object) {
      const model = new FAQ();
      return Object.assign(model, o);
    }
  }
