import { HttpParams } from '@angular/common/http';

export function createHttpParams(params: {}): HttpParams {
  let httpParams: HttpParams = new HttpParams();
  Object.keys(params).forEach(param => {
    if (params[param]) {
      if (params[param] instanceof Date)
        params[param] = params[param].toISOString();
      httpParams = httpParams.set(param, params[param]);
    }
  });

  return httpParams;
}
