import { BehaviorSubject, Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Language } from './models/language.model'
import { NotificationService } from '../services/notification/notification.service';

@Injectable()
export class LanguageService {

  private base_api_url: string = 'api/language';
  languages: Observable<Language[]>
  private languages$: BehaviorSubject<Language[]>;
  private dataStore: {
    languages: Language[]
  };
  isSaved: Observable<boolean>;
  private isSaved$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private notificationService:NotificationService) {
    this.dataStore = { languages: [] };
    this.languages$ = <BehaviorSubject<Language[]>>new BehaviorSubject([]);
    this.languages = this.languages$.asObservable();

    this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.isSaved = this.isSaved$.asObservable();
  }

  loadAll() {
    let $this = this;
    return this.http.get<Language[]>(`${this.base_api_url}?fromAdmin=true`, { observe: 'response' }).subscribe(resp => {
      let data:Language[] = new Array<Language>();
      resp.body.forEach(i => data.push(Language.createFrom(i)));
      $this.dataStore.languages = data;
      $this.languages$.next(({ ... $this.dataStore}).languages);
    }, error => {
      error.displayMessage = 'Could not load languages.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading languages, please try again later!');
      throw error;
    });
  }

  loadAllData() {
    return this.http.get<Language[]>(`${this.base_api_url}?fromAdmin=true`);
  }

  load(id: string) {
    return this.http.get<Language>(`${this.base_api_url}/${id}`).subscribe(data => {
      let notFound = true;

      this.dataStore.languages.forEach((item, index) => {
        if (item._id === data._id) {
          this.dataStore.languages[index] = data;
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.languages.push(data);
      }

      this.languages$.next(({ ... this.dataStore}).languages);
    }, error => {
      error.displayMessage = 'Could not load language.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading language, please try again later!');
      throw error;
    });
  }

  loadData(id: string) {
    return this.http.get<Language>(`${this.base_api_url}/${id}`);
  }

  create(language: Language) {
    this.processIsSaved();

    return this.http.post<Language>(this.base_api_url, language).subscribe(data => {
        console.log('successful create');
        this.dataStore.languages.push(data);
        this.languages$.next(({ ... this.dataStore}).languages);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Language has been created');
      }, error => {
        error.displayMessage = 'Could not create language.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error creating language, please try again later!');
        throw error;
      });
  }

  update(language: Language) {
    this.processIsSaved();

    return this.http.put<Language>(`${this.base_api_url}/${language._id}`, language)
      .subscribe(data => {
        this.dataStore.languages.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.languages[i] = data; }
        });

        this.languages$.next(({ ... this.dataStore}).languages);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Language has been updated');
      }, error => {
        error.displayMessage = 'Could not update language.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error updating language, please try again later!');
        throw error;
      });
  }

  remove(id: string) {
    return this.http.delete(`${this.base_api_url}/${id}`).subscribe(response => {
      this.dataStore.languages.forEach((t, i) => {
        if (t._id === id) { this.dataStore.languages.splice(i, 1); }
      });

      this.languages$.next(({ ... this.dataStore}).languages);
      this.notificationService.notifyInformation('Language has been deleted');
    }, error => {
      error.displayMessage = 'Could not delete language.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error deleting language, please try again later!');
      throw error;
    });
  }

  private processIsSaved() {
    this.isSaved
      .subscribe((value) => {
        console.log("service subscribe - " + value);
        if (value === true)
        { this.isSaved$.next(false); }
      });
  }
}
