import * as _ from "lodash";
import { BehaviorSubject, Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { File } from './models/file.model'
import { NotificationService } from '../services/notification/notification.service';

@Injectable()
export class FileService {

  private base_api_url: string = 'api/file';
  files: Observable<File[]>
  private files$: BehaviorSubject<File[]>;
  private dataStore: {
    files: File[]
  };
  isSaved: Observable<boolean>;
  private isSaved$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private notificationService:NotificationService) {
    this.dataStore = { files: [] };
    this.files$ = <BehaviorSubject<File[]>>new BehaviorSubject([]);
    this.files = this.files$.asObservable();

    this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.isSaved = this.isSaved$.asObservable();
  }

  loadAll() {
    let $this = this;
    return this.http.get<File[]>(this.base_api_url + "?fromAdmin=true", { observe: 'response' }).subscribe(resp => {
      let data:File[] = new Array<File>();
      resp.body.forEach(i => data.push(File.createFrom(i)));
      $this.dataStore.files = data;
      $this.files$.next(({ ... $this.dataStore}).files);
    }, error => {
      error.displayMessage = 'Could not load files.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading files, please try again later!');
      throw error;
    });
  }

  loadAllData() {
    return this.http.get<File[]>(this.base_api_url + "?fromAdmin=true");
  }

  load(id: string) {
    return this.http.get<File>(`${this.base_api_url}/${id}?fromAdmin=true`).subscribe(data => {
      let notFound = true;

      this.dataStore.files.forEach((item, index) => {
        if (item._id === data._id) {
          this.dataStore.files[index] = data;
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.files.push(data);
      }

      this.files$.next(({ ... this.dataStore}).files);
    }, error => {
      error.displayMessage = 'Could not load file.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading file, please try again later!');
      throw error;
    });
  }

  loadData(id: string) {
    return this.http.get<File>(`${this.base_api_url}/${id}?fromAdmin=true`);
  }

  createFormData(file: File) {
    if (file.file && file.file.name) {
      const formData = new FormData();
      formData.append("file", file.file, file.file.name);
      formData.append("name", file.name);
      return formData;
    } else {
      return file;
    }
  }

  create(file: File) {
    this.processIsSaved();

    const docToPost = this.createFormData(file);

    return this.http.post<File>(this.base_api_url, docToPost).subscribe(data => {
        console.log('successful create');
        this.dataStore.files.push(data);
        this.files$.next(({ ... this.dataStore}).files);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('File has been created');
      }, error => {
        error.displayMessage = 'Could not create file.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error creating file, please try again later!');
        throw error;
      });
  }

  async createForModel(file: File) {
    const docToPost = this.createFormData(file);
    return new Promise((resolve, reject) => {
      this.http.post<File>(this.base_api_url, docToPost)
      .subscribe(data => {
        this.dataStore.files.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.files[i] = data; }
        });

        this.files$.next(({ ... this.dataStore}).files);
        this.isSaved$.next(true);
        resolve(data._id);
      }, error => {
        error.displayMessage = 'Could not update file.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error updating file, please try again later!');
        reject(error);
      });
    });
  }

  update(file: File) {
    this.processIsSaved();

    const docToPost = this.createFormData(file);

    return this.http.put<File>(`${this.base_api_url}/${file._id}`, docToPost)
      .subscribe(data => {
        this.dataStore.files.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.files[i] = data; }
        });

        this.files$.next(({ ... this.dataStore}).files);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('File has been updated');
      }, error => {
        error.displayMessage = 'Could not update file.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error updating file, please try again later!');
        throw error;
      });
  }

  async updateForModel(file: File) {
    const docToPost = this.createFormData(file);
    return new Promise((resolve, reject) => {
      this.http.put<File>(`${this.base_api_url}/${file._id}`, docToPost)
      .subscribe(data => {
        this.dataStore.files.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.files[i] = data; }
        });

        this.files$.next(({ ... this.dataStore}).files);
        this.isSaved$.next(true);
        resolve(data._id);
      }, error => {
        error.displayMessage = 'Could not update file.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error updating file, please try again later!');
        reject(error);
      });
    });
  }

  remove(id: string) {
    return this.http.delete(`${this.base_api_url}/${id}`).subscribe(response => {
      this.dataStore.files.forEach((t, i) => {
        if (t._id === id) { this.dataStore.files.splice(i, 1); }
      });

      this.files$.next(({ ... this.dataStore}).files);
      this.notificationService.notifyInformation('File has been deleted');
    }, error => {
      error.displayMessage = 'Could not delete file.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error deleting file, please try again later!');
      throw error;
    });
  }

  
  // search(term: string, byName = false) {

  //   if (term === '') {
  //     return Observable.of([]);
  //   }

  //   let fields: any = { _id: true, name: true, file: true };
  //   fields = JSON.stringify(fields);

  //   const url = byName ?
  //   `${this.base_api_url}/search/name/${encodeURI(term)}?fields=${encodeURI(fields)}&excludeRelated&fromAdmin=true` :
  //   `${this.base_api_url}/search/${encodeURI(term)}?exludeRelated&fromAdmin=true`;

  //   return this.http
  //     .get<File[]>(url)
  //     .pipe(
  //       map(response => {
  //         return response["data"] as File[];
  //       },
  //     ));
  // }

  // searchByName(term: string) {
  //   return this.search(term, true);
  // }

  private processIsSaved() {
    this.isSaved
      .subscribe((value) => {
        console.log("service subscribe - " + value);
        if (value === true)
        { this.isSaved$.next(false); }
      });
  }
}
