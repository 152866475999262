export function currentUserLocation(){
    let data = JSON.parse(sessionStorage.userInfo);
    console.log(data);
    if(data.user.role.name == "Regional Admin"){
    if(data.user.location.name != null)
        return data.user.location.name;
    else
        return null;
    }
    return null;
}

export function currentUserID(){
    let data = JSON.parse(sessionStorage.userInfo);
    return data.user._id;
}

export function isSuperAdmin(){
    let data = JSON.parse(sessionStorage.userInfo);
    if(data.user != null){
        if(data.user.role.name == "Super Admin")
            return true;
        else
            return false;
    }
    else
        return false;
}
