import { Office } from './office.model';
import { Document } from './document.model';

export class EventContent {
    constructor(
        public title: string = null,
        public content: string = null,
        ) { }
    
    static createFrom(o: Object) {
        const model = new EventContent();
        return Object.assign(model, o);
    }
}

export class Event {
    constructor(
        public __v: number = 0,
        public _id: string = null,
        public title: string = null,
        public subtitle: string = null,
        public location: Office = null,
        public startDate: Date = null,
        public endDate: Date = null,
        public timeZone: string = null,
        public createdDate: Date = null,
        public imageBase64: string = null,
        public relatedEvents: Event[] = null,
        public imagesBase64: string[] = null,
        public registerUrl: string = null,
        public contents: EventContent[] = null,
        public published: boolean = false,
        public registerInternal: boolean = true,
        public createdBy: string = null,
        public relatedDocuments: Document[] = null,
        public images: any[] = null,
        public calendarFile: any = null
        ) { }
    
    static createFrom(o: Object) {
        const model = new Event();
        return Object.assign(model, o);
    }
}
  