import { Event } from './event.model';
import { Challenge } from './challenge.model';
import { Office } from './office.model';

export class NotificationType {
  constructor(
    public __v: number = 0,
    public _id: string = null,
    public typeId: string = null,
    public name: string = null,
    public description: string = null,
    ) { }

    static createFrom(o: Object) {
      const model = new NotificationType();
      return Object.assign(model, o);
    }
}

export class NotificationScheduleType {
  constructor(
    public name: string = null,
    public displayName: string = null,
    public isDefault: boolean = null
    ) { }

    static getList() {
      const result: NotificationScheduleType[] = new Array<NotificationScheduleType>();
      result.push(new NotificationScheduleType('specific', 'Specific', true));
      result.push(new NotificationScheduleType('relative', 'Relative', false));
      return result;
    }
}

export class NotificationRelativeField {
  constructor(
    public name: string = null,
    public displayName: string = null,
    public isDefault: boolean = null
    ) { }

    static getList() {
      const result: NotificationRelativeField[] = new Array<NotificationRelativeField>();
      result.push(new NotificationRelativeField('startDate', 'Start Date', true));
      result.push(new NotificationRelativeField('endDate', 'End Date', false));
      return result;
    }
}

export class NotificationRelativeHow {
  constructor(
    public name: string = null,
    public displayName: string = null,
    public isDefault: boolean = null
    ) { }

    static getList() {
      const result: NotificationRelativeHow[] = new Array<NotificationRelativeHow>();
      result.push(new NotificationRelativeHow('before', 'Before', true));
      result.push(new NotificationRelativeHow('on', 'On', false));
      result.push(new NotificationRelativeHow('after', 'After', false));
      return result;
    }
}

export class NotificationRelativeUnit {
  constructor(
    public name: string = null,
    public displayName: string = null,
    public isDefault: boolean = null
    ) { }

    static getList() {
      const result: NotificationRelativeUnit[] = new Array<NotificationRelativeUnit>();
      result.push(new NotificationRelativeUnit('hour', 'Hour', false));
      result.push(new NotificationRelativeUnit('day', 'Day', true));
      result.push(new NotificationRelativeUnit('week', 'Week', false));
      result.push(new NotificationRelativeUnit('month', 'Month', false));
      result.push(new NotificationRelativeUnit('year', 'Year', false));
      return result;
    }
}

export class NotificationSchedule {
  constructor(
    public __v: number = 0,
    public _id: string = null,
    public type: string = null,// 'specific, relative', default: 'specific'
    public specificDate: Date = null,
    public relativeField: string = null,// 'startDate, endDate', default: 'startDate'
    public relativeHow: string = null,// options: 'before, on, after', default: 'before'
    public relativeUnit: string = null,// options: 'hour, day, week, month, year', default: 'day'
    public relativeValue: number = 0,
    ) { }

    static createFrom(o: Object) {
      const model = new NotificationSchedule();
      return Object.assign(model, o);
    }
}

export class Notification {
  constructor(
    public __v: number = 0,
    public _id: string = null,
    public name: string = null,
    public description: string = null,
    public schedule: NotificationSchedule = null,
    public title: string = null,
    public message: string = null,
    public active: boolean = null,
    public complete: boolean = null,
    public isDefault: boolean = null,
    public notificationType: NotificationType = null,
    public challenge: Challenge = null,
    public event: Event = null,
    public createdDate: Date = null,
    public updatedDate: Date = null,
    public location: Office = null,
    public published: boolean = false,
    ) {
      if (!schedule) { schedule = new NotificationSchedule(); }
    }

    static createFrom(o: Object) {
      const model = new Notification();
      return Object.assign(model, o);
    }
}
