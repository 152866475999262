import { FAQ } from './faq.model'
import * as _ from "lodash";

export class FAQCategory {
  constructor(
    public __v: number = 0,
    public _id: string = null,
    public name: string = null,
    public order: number = 0,
    public faqs: FAQ[] = null) { }
  
  static createFrom(o: Object) {
    const model = new FAQCategory();
    const faqs = _.cloneDeep(o['faqs']);
    if (o['faqs'] != null) {
      o['faqs'].length = 0;
    }
    else {
      o['faqs'] = new Array<FAQ>();
    }
    Object.assign(model, o);

    model.faqs = new Array<FAQ>();
    if (faqs != null) {
      faqs.forEach(i => model.faqs.push(FAQ.createFrom(i)));
    }

    return model;
  }
}
