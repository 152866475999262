import * as _ from 'lodash';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router, Event, NavigationError } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import * as StackTraceParser from 'error-stack-parser';
import { parse, stringify } from 'flatted/esm';

export class Log {
  constructor(
    public __v: number,
    public _id: string,
    public application: string,
    public level: string,
    public createdDate: Date,
    public content: Object,
  ) { }
}

@Injectable()
export class ErrorsService {

  constructor(
    private injector: Injector,
    private router: Router,
    private http: HttpClient,
  ) {
    // Subscribe to the NavigationError
    this.router
      .events
      .subscribe((event: Event) => {
        if (event instanceof NavigationError) {
          // Redirect to the ErrorComponent
          this.log(event.error)
            .subscribe((errorWithContext) => {
              this.router.navigate(['/error'], { queryParams: errorWithContext })
            });
        }
      });
  }

  log(error) {
    // Log the error to the console
    console.log(error);
    // Send error to server
    const errorToSend = this.addContextInfo(error);
    let log: Log = new Log(0, null, 'citi-livewell-admin', null, new Date(), errorToSend);
    return this.http.post<Log>(`api/log`, log)
  }

  addContextInfo(error) {
    // You can include context details here (usually coming from other services: UserService...)
    const name = error.name || null;
    const appId = 'citi-livewell';
    const user = 'add user here';
    const time = new Date();
    const id = `${appId}-${user}-${time}`;
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    const status = error.status || null;
    const message = error.message || error.toString();
    const stack = error instanceof HttpErrorResponse ? null : StackTraceParser.parse(error);
    // const full = serializeError(error);
    let full = {}
    full["message"] = error ? error.message : null;
    full["stack"] = stack;

    const errorWithContext = {
      name: name,
      appId: appId,
      user: user,
      time: time,
      id: id,
      url: url,
      status: status,
      message: message,
      stack: stack,
      full: full
    };
    return errorWithContext;
  }
}

class fakeHttpService {
  static post(error): Observable<any> {
    console.log('Error sent to the server: ', error);
    return Observable.of(error);
  }
}
