import { Country, Region } from './office.model';
import { Challenge } from './challenge.model';
import { NameValue } from './nameValue.model';

export class Report {
  constructor(
    public __v: number = 0,
    public _id: string = null,
    public name: string = null,
    public filters: any = null
  ) {}

  static createFrom(o: Object) {
    const model = new Report();
    return Object.assign(model, o);
  }
}

export class ReportConfiguration {
  constructor(
    public reports: NameValue[] = null,
    public regions: NameValue[] = null,
    public countries: NameValue[] = null,
    public challenges: NameValue[] = null
  ) {}

  static createFrom(o: Object) {
    const model = new ReportConfiguration();
    return Object.assign(model, o);
  }
}
