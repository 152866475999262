import * as _ from "lodash";
import { BehaviorSubject, Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { LandingSlide } from './models/landingSlide.model'
import { NotificationService } from "../services/notification/notification.service";
import { currentUserID, currentUserLocation, isSuperAdmin } from '../utils/session';
import { environment } from '../../../environments/environment';

@Injectable()
export class LandingSlideService {

  private base_api_url: string = 'api/landingslide';
  landingSlides: Observable<LandingSlide[]>
  landingSlidesAll: Observable<LandingSlide[]>
  private landingSlides$: BehaviorSubject<LandingSlide[]>;
  private landingSlidesAll$: BehaviorSubject<LandingSlide[]>;
  private dataStore: {
    landingSlides: LandingSlide[];
    landingSlidesAll: LandingSlide[];
  };
  isSaved: Observable<boolean>;
  private isSaved$: BehaviorSubject<boolean>;
  private userLoc: string;
  private isSA: boolean;
  private userId : string;

  constructor(private http: HttpClient, private notificationService:NotificationService) {
    this.dataStore = { landingSlides: [], landingSlidesAll: [] };
    this.landingSlides$ = <BehaviorSubject<LandingSlide[]>>new BehaviorSubject([]);
    this.landingSlidesAll$ = <BehaviorSubject<LandingSlide[]>>new BehaviorSubject([]);
    this.landingSlides = this.landingSlides$.asObservable();
    this.landingSlidesAll = this.landingSlidesAll$.asObservable();

    this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.isSaved = this.isSaved$.asObservable();

    this.userLoc = currentUserLocation();
    this.isSA = isSuperAdmin();
    this.userId = currentUserID();
  }

  loadAll(fields: string | object = null, includeBase64 = false) {
    let $this = this;
    let base64 = "";
    if (includeBase64) base64 = "&includeBase64";

    // 9/30/2022 filter by regional Admin and location
    let url = "";
    if (!this.isSA && environment.viewUserCreatedData) {
      url = "?fromAdmin=true" + "&authorId=" + this.userId + base64;
    } else if (!this.isSA && !environment.viewUserCreatedData) {
      url = "/office/" + this.userLoc + "?fromAdmin=true" + base64;
    } else {
      url = "?fromAdmin=true" + base64;
    }
      
    return this.http.get<LandingSlide[]>(this.base_api_url + url, { observe: 'response' }).subscribe(resp => {
      let data:LandingSlide[] = new Array<LandingSlide>();
      resp.body.forEach(i => {
        data.push(LandingSlide.createFrom(i));
      });
      $this.dataStore.landingSlides = data;
      $this.landingSlides$.next(({ ... $this.dataStore}).landingSlides);
    }, error => {
      error.displayMessage = 'Could not load landingSlides.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading slides, please try again later!');
      throw error;
    });
  }
  
  loadAllNotRA(fields: string | object = null, includeBase64 = false) {
    let $this = this;
    let base64 = "";
    if (includeBase64) base64 = "&includeBase64";

    // 9/30/2022 filter by regional Admin and location
    let url = "?fromAdmin=true" + base64;
      
    return this.http.get<LandingSlide[]>(this.base_api_url + url, { observe: 'response' }).subscribe(resp => {
      let data:LandingSlide[] = new Array<LandingSlide>();
      resp.body.forEach(i => {
        data.push(LandingSlide.createFrom(i));
      });
      $this.dataStore.landingSlidesAll = data;
      $this.landingSlidesAll$.next(({ ... $this.dataStore}).landingSlidesAll);
    }, error => {
      error.displayMessage = 'Could not load landingSlides.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading slides, please try again later!');
      throw error;
    });
  }

  loadAllData() {
    return this.http.get<LandingSlide[]>(this.base_api_url);
  }

  load(id: string) {
    return this.http.get<LandingSlide>(`${this.base_api_url}/${id}`).subscribe(data => {
      let notFound = true;

      this.dataStore.landingSlides.forEach((item, index) => {
        if (item._id === data._id) {
          this.dataStore.landingSlides[index] = data;
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.landingSlides.push(data);
      }

      this.landingSlides$.next(({ ... this.dataStore}).landingSlides);
    }, error => {
      error.displayMessage = 'Could not load slide.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error loading slide, please try again later!');
      throw error;
    });
  }

  loadData(id: string) {
    return this.http.get<LandingSlide>(`${this.base_api_url}/${id}?includeBase64`);
  }

  // search(term: string, byTitle = false) {

  //   if (term === '') {
  //     return Observable.of([]);
  //   }

  //   let fields: any = { _id: true, title: true };
  //   fields = JSON.stringify(fields);

  //   const url = byTitle ?
  //   `${this.base_api_url}/search/title/${encodeURI(term)}?fields=${encodeURI(fields)}&excludeRelated&fromAdmin=true` :
  //   `${this.base_api_url}/search/${encodeURI(term)}?exludeRelated&fromAdmin=true`;

  //   return this.http
  //     .get<LandingSlide[]>(url)
  //     .pipe(
  //       map(response => {
  //         return response["data"] as LandingSlide[];
  //       },
  //     ));
  // }

  // searchByTitle(term: string) {
  //   return this.search(term, true);
  // }

  create(article: LandingSlide) {
    this.processIsSaved();

    return this.http.post<LandingSlide>(this.base_api_url, article).subscribe(data => {
        console.log('successful create');
        this.dataStore.landingSlides.push(data);
        this.landingSlides$.next(({ ... this.dataStore}).landingSlides);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Slide has been created');
      }, error => {
        error.displayMessage = 'Could not create slide.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error creating slide, please try again later!');
        throw error;
      });
  }

  update(article: LandingSlide) {
    this.processIsSaved();

    return this.http.put<LandingSlide>(`${this.base_api_url}/${article._id}`, article)
      .subscribe(data => {
        this.dataStore.landingSlides.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.landingSlides[i] = data; }
        });

        this.landingSlides$.next(({ ... this.dataStore}).landingSlides);
        this.isSaved$.next(true);
        this.notificationService.notifyInformation('Slide has been updated');
      }, error => {
        error.displayMessage = 'Could not update slide.';
        console.log(error.displayMessage);
        this.notificationService.notifyError('Error updating slide, please try again later!');
        throw error;
      });
  }

  remove(id: string) {
    return this.http.delete(`${this.base_api_url}/${id}`).subscribe(response => {
      this.dataStore.landingSlides.forEach((t, i) => {
        if (t._id === id) { this.dataStore.landingSlides.splice(i, 1); }
      });

      this.landingSlides$.next(({ ... this.dataStore}).landingSlides);
      this.notificationService.notifyInformation('Slide has been deleted');
    }, error => {
      error.displayMessage = 'Could not delete slide.';
      console.log(error.displayMessage);
      this.notificationService.notifyError('Error removing slide, please try again later!');
      throw error;
    });
  }

  private processIsSaved() {
    this.isSaved
      .subscribe((value) => {
        console.log("service subscribe - " + value);
        if (value === true)
        { this.isSaved$.next(false); }
      });
  }
}
