
export class RegistrationGetStarted {
  constructor(
      public __v: number = 0,
      public _id: string = null,
      public title: string = null,
      public createdDate: Date = null,
      public imageBase64: string = null,
      public isDelete: boolean = false,
      ) { }
  
  static createFrom(o: Object) {
      const model = new RegistrationGetStarted();
      return Object.assign(model, o);
  }
}

export class RegistrationType {
    constructor(
      public __v: number = 0,
      public _id: string = null,
      public name: string = null,
      public display: string = null,
      ) { }
    
      static createFrom(o: Object) {
        const model = new RegistrationType();
        return Object.assign(model, o);
      }
  }

export class Registration {
    constructor(
      public __v: number = 0,
      public _id: string = null,
      public name: string = null,
      public type: RegistrationType = null,
      public getStartedes: RegistrationGetStarted[] = null,
      public termsAndConditions: string = null,
      public privacyPolicy: string = null,
      ) { }
    
      static createFrom(o: Object) {
        const model = new Registration();
        return Object.assign(model, o);
      }
  }
  