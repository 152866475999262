import { BehaviorSubject, Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { createHttpParams } from '../utils/http-params';

import { saveAs } from 'file-saver';
import 'rxjs/Rx';
import { EventReportConfiguration } from './models/event-report.model';

@Injectable()
export class EventReportService {
  private base_api_url: string = 'api/reports';
  // reports: Observable<Report[]>;
  // private reports$: BehaviorSubject<Report[]>;
  // private dataStore: {
  //   reports: Report[];
  // };
  // isSaved: Observable<boolean>;
  // private isSaved$: BehaviorSubject<boolean>;

  reportConfiguration: Observable<EventReportConfiguration>;

  private reportConfiguration$: BehaviorSubject<EventReportConfiguration>;
  private dataStore: {
    reportConfiguration: EventReportConfiguration;
  };
  isSaved: Observable<boolean>;
  private isSaved$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient) {
    // this.dataStore = { reports: [] };
    // this.reports$ = <BehaviorSubject<Report[]>>new BehaviorSubject([]);
    // this.reports = this.reports$.asObservable();
    // this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    // this.isSaved = this.isSaved$.asObservable();
    this.dataStore = {
      reportConfiguration: {
        reports: [],
        events: [],
        regions: [],
        countries: [],
      },
    };
    this.reportConfiguration$ = <BehaviorSubject<EventReportConfiguration>>(
      new BehaviorSubject({})
    );
    this.reportConfiguration = this.reportConfiguration$.asObservable();

    this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.isSaved = this.isSaved$.asObservable();
  }

  loadAll() {
    let $this = this;
    return this.http
      .get<EventReportConfiguration>(`${this.base_api_url}/events/config`, {
        observe: 'response',
      })
      .subscribe(
        resp => {
          let data: EventReportConfiguration = new EventReportConfiguration();
          data = EventReportConfiguration.createFrom(resp.body);
          $this.dataStore.reportConfiguration = data;
          $this.reportConfiguration$.next(
            { ...$this.dataStore }.reportConfiguration
          );
        },
        error => {
          error.displayMessage = 'Could not load reports.';
          console.log(error.displayMessage);
          throw error;
        }
      );
  }

  generate({ report, locationType, location,event, eventName,startDate, endDate }) {
    const params = createHttpParams({
      report,
      event,
      eventName,
      locationType,
      location,
      startDate,
      endDate,
    });
    return this.http
      .get(`${this.base_api_url}/events/generate`, {
        params,
        responseType: 'arraybuffer',
      })
      .subscribe(
        resp => {
          return this.saveToFileSystem(resp,eventName);
        },
        error => {
          error.displayMessage = 'Could not download report file.';
          console.log(error.displayMessage);
          throw error;
        }
      );
  }

  private saveToFileSystem(response,eventName) {
    // const contentDispositionHeader: string =
    //   response.headers && response.headers.get('Content-Disposition');
    // const parts: string[] =
    //   contentDispositionHeader && contentDispositionHeader.split(';');
    // const filename = parts && parts[1].split('=')[1];
    const blob = new Blob([response], { type: 'text/csv' });
    if(eventName){
      eventName = eventName.replace(' ', '-')
      saveAs(blob, eventName + '-Registrants.csv');
    }
    else
      saveAs(blob, 'Event-Registrants.csv');
  }
}
