import { BehaviorSubject, Observer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { FAQ } from './models/faq.model'

@Injectable()
export class FAQService {

  private base_api_url: string = 'api/faq';
  faqs: Observable<FAQ[]>
  private faqs$: BehaviorSubject<FAQ[]>;
  private dataStore: {
    faqs: FAQ[]
  };
  isSaved: Observable<boolean>;
  private isSaved$: BehaviorSubject<boolean>;

  constructor(private http: HttpClient) {
    this.dataStore = { faqs: [] };
    this.faqs$ = <BehaviorSubject<FAQ[]>>new BehaviorSubject([]);
    this.faqs = this.faqs$.asObservable();

    this.isSaved$ = <BehaviorSubject<boolean>>new BehaviorSubject(false);
    this.isSaved = this.isSaved$.asObservable();
  }

  loadAll() {
    let $this = this;
    return this.http.get<FAQ[]>(`${this.base_api_url}`, { observe: 'response' }).subscribe(resp => {
      let data:FAQ[] = new Array<FAQ>();
      resp.body.forEach(i => data.push(FAQ.createFrom(i)));
      $this.dataStore.faqs = data;
      $this.faqs$.next(({ ... $this.dataStore}).faqs);
    }, error => {
      error.displayMessage = 'Could not load faqs.';
      console.log(error.displayMessage);
      throw error;
    });
  }

  load(id: string) {
    return this.http.get<FAQ>(`${this.base_api_url}/${id}`).subscribe(data => {
      let notFound = true;

      this.dataStore.faqs.forEach((item, index) => {
        if (item._id === data._id) {
          this.dataStore.faqs[index] = data;
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.faqs.push(data);
      }

      this.faqs$.next(({ ... this.dataStore}).faqs);
    }, error => {
      error.displayMessage = 'Could not load faq.';
      console.log(error.displayMessage);
      throw error;
    });
  }

  loadData(id: string) {
    return this.http.get<FAQ>(`${this.base_api_url}/${id}?includeBase64`);
  }

  create(faq: FAQ) {
    this.processIsSaved();

    return this.http.post<FAQ>(`${this.base_api_url}`, faq).subscribe(data => {
        console.log('successful create');
        this.dataStore.faqs.push(data);
        this.faqs$.next(({ ... this.dataStore}).faqs);
        this.isSaved$.next(true);
      }, error => {
        error.displayMessage = 'Could not create faq.';
        console.log(error.displayMessage);
        throw error;
      });
  }

  update(faq: FAQ) {
    this.processIsSaved();

    return this.http.put<FAQ>(`${this.base_api_url}/${faq._id}`, faq)
      .subscribe(data => {
        this.dataStore.faqs.forEach((t, i) => {
          if (t._id === data._id) { this.dataStore.faqs[i] = data; }
        });

        this.faqs$.next(({ ... this.dataStore}).faqs);
        this.isSaved$.next(true);
      }, error => {
        error.displayMessage = 'Could not update faq.';
        console.log(error.displayMessage);
        throw error;
      });
  }

  remove(id: string) {
    return this.http.delete(`${this.base_api_url}/${id}`).subscribe(response => {
      this.dataStore.faqs.forEach((t, i) => {
        if (t._id === id) { this.dataStore.faqs.splice(i, 1); }
      });

      this.faqs$.next(({ ... this.dataStore}).faqs);
    }, error => {
      error.displayMessage = 'Could not delete faq.';
      console.log(error.displayMessage);
      throw error;
    });
  }

  private processIsSaved() {
    this.isSaved
      .subscribe((value) => {
        console.log("service subscribe - " + value);
        if (value === true)
        { this.isSaved$.next(false); }
      });
  }
}
